import {Inject, Injectable} from '@angular/core';
import {HttpService} from '../http/http.service';
import {GeneralSettingValue} from '../../models';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GeneralSettingValueService extends HttpService<GeneralSettingValue, GeneralSettingValue> {
  protected endpoint: string = 'general-settings';

  constructor(http: HttpClient,
              @Inject('FRAMEWORK_BASEURL') protected baseUrl: string) {
    super(http);
  }

  findByCode(code: string, societyId: number): Observable<GeneralSettingValue[]> {
    const params = {
      societyId: `${societyId}`
    };

    return this.get<GeneralSettingValue[]>(`byCode/${code}`, {params});
  }

  convertModelToResource(model: GeneralSettingValue): GeneralSettingValue {
    return {...model};
  }

  convertResourceToModel(resource: GeneralSettingValue): GeneralSettingValue {
    return {...resource};
  }

}
