<!--<div class="wrapper" (window:resize)="onResize($event)">-->
<!--  <app-sidebar #sideBar *ngIf="currentUser"></app-sidebar>-->
<!--  <div id="content">-->
<!--    <div class="container-fluid">-->
<!--      &lt;!&ndash; header &ndash;&gt;-->

<!--      <app-header #header id="sidebar" [languages]="languages" [currentLanguage]="currentLanguage" *ngIf="currentUser"-->
<!--                  (updateLanguage)="useLanguage($event)"></app-header>-->

<!--      &lt;!&ndash; routes will be rendered here &ndash;&gt;-->

<!--      <main>-->
<!--          <router-outlet></router-outlet>-->
<!--      </main>-->

<!--    </div>-->
<!--    &lt;!&ndash; footer &ndash;&gt;-->
<!--    <app-footer *ngIf="currentUser"></app-footer>-->
<!--  </div>-->
<!--</div>-->
<router-outlet></router-outlet>
