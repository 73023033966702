import { EventEmitter, Output, PipeTransform, QueryList, ViewChildren, Directive } from '@angular/core';
import {NgbdSortableHeader, SortDirection, SortEvent} from '../directives/sortable.directive';
import {LocalDate} from '../models/local-date.model';
import {Observable} from 'rxjs';
import {ListServiceHelper} from './list-service-helper.service';
import {isNullOrUndefined} from 'util';
import {SelectableModel} from '../models/selectable-model';


export function compare(v1, v2) {
    return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
}

export function sort(list: any[], column: string, direction: string): any[] {
    if (direction === '') {
        return list;
    } else {
        const columnElems = column.split('.');
        return [...list].sort((a, b) => {
            let aVal = a;
            let bVal = b;
            columnElems.map( x => {
                aVal = !isNullOrUndefined(aVal) ? aVal[x] : '';
                bVal = !isNullOrUndefined(bVal) ? bVal[x] : '';
                if (x === 'season') {
                    aVal = (aVal.year * 100 + aVal.month);
                    bVal = (bVal.year * 100 + bVal.month);
                }
            });
            const res = compare(aVal, bVal);
            return direction === 'asc' ? res : -res;
        });
    }
}

export function matches(ana: any, term: string, pipe: PipeTransform) {
    return ana;
}

export interface SearchResult {
    list: any[];
    filteredList?: any[];
    total: number;
}

export interface State {
    page?: number;
    pageSize?: number;
    searchTerm?: string;
    searchID?: string;
    searchType?: number;
    searchName?: string;
    searchStatus?: any;
    searchSeason?: number;
    searchCompany?: number;
    sortColumn?: string;
    searchYearVal?: boolean;
    dirSelected?: number;
    searchEmpty?: number;
    searchPeriod?: string;
    searchDefault?: any;
    searchInfoSet?: number;
    searchInfoSetTemplate?: number;
    searchProgress?: boolean;
    searchDateFrom?: LocalDate;
    searchDateTo?: LocalDate;
    searchReminderDateFrom?: LocalDate;
    searchReminderDateTo?: LocalDate;
    searchRole?: any;
    searchTraceProcess?: string;
    searchTraceActivity?: string;
    searchUsername?: string;
    searchValue?: string;
    sortDirection?: SortDirection;
}

@Directive()
export class ListHelper<T extends SelectableModel> {

    @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
    @Output() showLoading = new EventEmitter<boolean>();
    @Output() openDetail = new EventEmitter<object>();

    list$: Observable<T[]>;
    filteredList$: Observable<T[]>;
    total$: Observable<number>;
    total: number;
    selectedList: T[] = [];
    filteredList: T[] = [];
    selectedCount: number = 0;
    selectAll: boolean;

    constructor(public service: ListServiceHelper<T>) {
        this.list$ = service.list$;
        this.total$ = service.total$;
        this.filteredList$ = service.filteredList$;
    }

    public onSort({column, direction}: SortEvent) {
        // resetting other headers
        this.headers.forEach(header => {
            if (header.sortable !== column) {
                header.direction = '';
            }
        });

        this.service.sortColumn = column;
        this.service.sortDirection = direction;
    }

    onShowLoading(show: boolean) {
        this.showLoading.emit(show);
    }

    public resetFilters() {
        this.service.searchID = '';
        this.service.searchName = '';
        this.service.searchType = null;
        this.service.searchDefault = '';
        this.service.searchCompany = null;
        this.service.searchDateFrom = null;
        this.service.searchDateTo = null;
        this.service.searchInfoSet = null;
        this.service.searchInfoSetTemplate = null;
        this.service.searchProgress = null;
        this.service.searchReminderDateFrom = null;
        this.service.searchReminderDateTo = null;
        this.service.searchRole = null;
        this.service.searchSeason = null;
        this.service.searchStatus = '';
        this.service.searchYearVal = null;
        this.service.searchTraceProcess = '';
        this.service.searchTraceActivity = '';
        this.service.searchTerm = '';
        this.service.searchPeriod = '';
        this.service.searchRole = '';
        this.service.searchValue = '';
        this.service.searchUsername = '';
    }

    public selectVisibleRows(selected: boolean) {
        this.list$.forEach(list => {
            // console.log('list', list);
            list.forEach(i => {
                i.selected = selected;
            });
            if (selected) {
                this.selectedList = list.filter(e => e.selected);
            } else {
                this.selectedList = [];
            }
            this.selectedCount = this.selectedList.length;
        });
        this.selectAll = selected;
    }

    public selectAllRows() {
        this.list$.forEach(list => {
            // console.log('list', list);
            list.forEach(i => {
                i.selected = true;
            });
            this.selectedList = this.service.list;
            this.selectedCount = this.selectedList.length;
        });
        this.selectAll = true;
    }

    public selectRows(id: number, selected: boolean) {
        // console.log('selected', selected);
        this.list$.forEach(list => {
            // console.log('list', list);
            list.map(i => {
                i.selected = (i.id === id) ? selected : i.selected;
            });
            this.selectedList = list.filter(e => e.selected);
            this.selectedCount = this.selectedList.length;
        });
        this.selectAll = false;
    }

    public exportAsExcel(list?: any[]) {
        // console.log('this.filteredList', isNullOrUndefined(list));
        if (isNullOrUndefined(list)) {
            list = this.filteredList;
        }
        this.service.exportAsExcel(list);
    }
}
