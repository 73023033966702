import {Component, Input} from '@angular/core';

@Component({
  selector: 'baloon',
  templateUrl: './baloon.component.html',
  styleUrls: ['./baloon.component.scss']
})
export class BaloonComponent {
  @Input() content: string;
  @Input() color: string = '#d04a02';

  constructor() {
  }
}
