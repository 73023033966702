import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Country} from '../models/country.model';

@Injectable({
  providedIn: 'root'
})
export class BussSelService {

  constructor(private http: HttpClient) {
  }

  getAll() {
    return this.http.get<Country[]>(`${environment.apiUrl}/business-selector`);
  }
}
