<widget title="News & Alert" link="#" [contentRef]="widgetContent"></widget>

<ng-template #widgetContent>
  <div class="list-group list-group-flush">
    <a href="#" class="list-group-item list-group-item-action">
      <div class="entry">
        <p class="entry-title">
          Intermediazione illecita e sfruttamento del lavoro: le precisazioni dell'INL
        </p>
        <p class="entry-content">
          A cura di Michele Giammusso e Francesca Tironi
        </p>
        <p class="entry-meta">
          Gio 07/03/2019
        </p>
      </div>
    </a>
  </div>
</ng-template>
