// noinspection TypeScriptPreferShortImport
import {UserTypeEnum} from '../../enum/user-type.enum';
import {SelectableModel} from '../base/selectable-model';

export class User extends SelectableModel {
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  fiscalCode?: string;
  email?: string;
  token?: string;
  language: string;
  enabled?: boolean = true;
  passwordExpire?: Date;
  userType?: UserTypeEnum;
  vat?: string;
  companyName?: string;
  phone?: string;
  privacy?: boolean;
  termsConditions?: boolean;

  origin?: string;

  userInfoEcobonus?: any;
  userInfoDiagnostico?: any;
}
