import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {AlertModal} from '../../../models/alert-modal.model';

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

    constructor(private ngxSmartModalService: NgxSmartModalService) {}
    type: string;

    @Input() model: any;
    @Output() onAnyCloseEmitter = new EventEmitter<string>();

    ngOnInit() {
        this.type = '';
    }
    openModalAlert(id: any, type: string, message: string) {
        // console.log('ALERT:',id, type, message);
        const obj: AlertModal = new AlertModal(id, type, message);
        this.type = obj.type;
        this.ngxSmartModalService.setModalData(obj, 'alertModal', true);
        this.ngxSmartModalService.getModal('alertModal').open();
    }

    onAnyClose(e) {
        // console.log('close alert');
        this.onAnyCloseEmitter.emit(this.type);
    }

    getHeaderMessage() {
        if (this.type === 'success' || this.type.toLowerCase().includes('success')) {
            return 'INFO';
        } else if (this.type === 'error' || this.type.toLowerCase().includes('error')) {
            return 'ALERT';
        }
    }

}
