export const baseEnvironment = {
  production: true,
  cookieDomain: 'localhost',
  cookiePath: '/',
  secureFlag: true,
  apiUrl: 'https://localhost:8080',
  meUrl: 'https://localhost:4300',
  idStringLength: 3,
  encryption: {
    secret: 'TCkOI9NnOVmVRBKRnEPUWBeMp9F3GlsU',
    salt: '6bae6f515e6099b3b104eaaf9fe7861f',
    iv: '647a94ccb5fad019165cf9f7a81d9dea'
  },
  ecobonusFlag: false,
  url2086: '#',
  urlDocumentale: '#',
  urlEcobonus: '#',
  urlDiagnostico: '#',
  urlCorporateGovernance: '#',
  urlDpo: '#',
  features: {
    faq: false
  }
};
