<widget title="Pubblicazioni" link="#" [contentRef]="widgetContent"></widget>

<ng-template #widgetContent>
  <div class="list-group list-group-flush">
    <a href="#" class="list-group-item list-group-item-action">
      <div class="entry">
        <p class="entry-title">
          Legge di bilancio 2019 - Introduzione della Web tax in Italia
        </p>
        <p class="entry-content">
          A cura di Dario Sencar e Giovanni Falsitta
        </p>
        <p class="entry-meta">
          Mar 08/01/2019
        </p>
      </div>
    </a>
  </div>
</ng-template>
