import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {Team} from '../models/team';
import {CompanyService} from 'src/app/services/company.service';
import {TeamsService} from 'src/app/services/teams.service';
import {Company} from 'src/app/models/company.model';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {switchMap, tap, first} from 'rxjs/operators';
import {of} from 'rxjs';
import {Authorities} from 'src/app/enums/security/authorities.enum';
import {TranslateService} from '@ngx-translate/core';
import {mapTranslateMessages, tranlastionKeys} from 'src/app/helpers/generic-utils';
import {SocietyEntity} from 'src/app/models/dto/entities/society-entity';

@Component({
  selector: 'app-teams-details',
  templateUrl: './teams-details.component.html',
  styleUrls: ['./teams-details.component.scss']
})
export class TeamsDetailsComponent implements OnInit {

  team: Team = new Team();
  companies: Company[] = [];
  submitted: boolean = false;
  initialized: boolean = false;
  loading: boolean = false;
  permissions: string;
  id: number;
  groups: Team[] = [];

  @ViewChild('alertModal', {static: true}) alertModal: any;

  requiredAuthorities = {
    team: {
      read: Authorities.ROLE_COMPANY_READ,
      create: Authorities.ROLE_COMPANY_CREATE,
      update: Authorities.ROLE_COMPANY_UPDATE,
      delete: Authorities.ROLE_COMPANY_DELETE,
    }
  };

  messages: any = new Object();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private companyService: CompanyService,
    private teamService: TeamsService,
  ) {
    this.initTranslations();
  }

  initTranslations() {
    if (this.translateService == null) {
      return;
    }
    this.translateService.get(tranlastionKeys).subscribe(translated => {
      this.messages = mapTranslateMessages(translated);
    });
  }

  ngOnInit(): void {
    this.companyService.getAll().subscribe(data => {
      this.companies = data;
      this.loadTeam();
    });

    if (this.router.url.endsWith('/new')) {
      this.id = null;
      this.initialized = true;
    } else {
      this.route.params.subscribe(params => {
        // console.log('params', params);
        this.id = +params.teamId; // (+) converts string 'id' to a number
        this.permissions = params.permissions;
      });
    }
  }

  loadTeam() {
    this.route.paramMap.pipe(
      switchMap((params: ParamMap) => {
        if (params.has('id')) {
          return of(+params.get('id'));
        }
        return of(null);
      }),
      tap((id: number | null) => {
        if (id != null) {
          return;
        }
      }),
      switchMap((id: number) => id == null ? of(null) : this.teamService.detail(id))
    ).subscribe((team: Team) => {
      if (team == null) {
        return;
      }

      this.team = {...team};
      this.fillSociety();
      this.onSocietyChange();
      this.initialized = true;
    });
  }

  fillSociety() {
    if (this.team.societyId != null) {
      this.team.society = this.getSocietyById(this.team.societyId);
    }
  }

  getSocietyById(societyId: number): SocietyEntity {
    let foundCompanies = this.companies.filter((item: SocietyEntity) => {
      return item.id == societyId;
    });
    if (foundCompanies.length > 0) {
      return foundCompanies[0];
    } else {
      return null;
    }
  }

  goTolist() {
    this.router.navigateByUrl('teams');
  }

  get readonly() {
    return this.permissions === 'r';
  }

  save() {
    this.submitted = true;
    if (this.checkFormValid()) {
      if (this.team.id == null) {
        this.teamService.insert(this.team).subscribe(res => {
            this.openAlert(0, 'success', this.messages.savingSuccess);
            this.loading = false;
          }
          , (err) => {
            // console.log(err);
            if (err.status === 422 && err.error.type === 'unique_constraint_violated') {
              const msg = `${this.messages.validationFieldDuplicated} ${err.error.details.property}`;
              this.openAlert(0, 'error', msg);
            } else {
              this.openAlert(0, 'error', this.messages.errorSaving);
            }
            this.loading = false;
          });
      } else {
        this.teamService.update(this.team).subscribe(res => {
            this.openAlert(0, 'success', this.messages.savingSuccess);
            this.loading = false;
          }
          , (err) => {
            // console.log(err);
            if (err.status === 422 && err.error.type === 'unique_constraint_violated') {
              const msg = `${this.messages.validationFieldDuplicated} ${err.error.details.property}`;
              this.openAlert(0, 'error', msg);
            } else {
              this.openAlert(0, 'error', this.messages.errorSaving);
            }
            this.loading = false;
          });
      }
    }
  }

  callbackApprovation(type: string) {
    if (type === 'success') {
      this.goTolist();
    }
  }

  checkFormValid() {
    return this.team.teamName && this.team.teamName.trim() != '' && this.team.societyId && this.team.societyId > 0;
  }

  openAlert(id: any, type: string, message: string) {
    this.alertModal.openModalAlert(id, type, message);
  }

  onSocietyChange(): void {
    if (this.team.societyId == null) {
      this.groups = [];
      this.team.parentId = null;
      return;
    }

    this.teamService.search({societyId: this.team.societyId})
      .pipe(first())
      .subscribe((teams: Team[]) => {
        this.groups = teams;
      }, error => {
        console.error(error);
      });
  }
}
