import {Entity} from "./entity";
import {User} from "../../user.model";
import {Company} from "../../company.model";
import {GeneralSettingEntity} from "./general-setting-entity";

export class GeneralSettingValueEntity extends Entity {
    value: string;
    setting: GeneralSettingEntity;
    settingId: number;
    user: User;
    userId: number;
    society: Company;
    societyId: number;
}
