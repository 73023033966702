import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export abstract class QueueService<T> {
  protected listSubject: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);
  public list$: Observable<T[]> = this.listSubject.asObservable();

  public abstract removeById(id: number): void;

  public clear(): void {
    this.listSubject.next([]);
  }

  public isEmpty(): boolean {
    return this.listSubject.getValue().length === 0;
  }

  public isNotEmpty(): boolean {
    return !this.isEmpty();
  }

  public count(): number {
    return this.listSubject.getValue().length;
  }
}
