import {HttpParams} from '@angular/common/http';
import {DateUtils} from './date-utils';
import {TimeUtils} from './time-utils';

export class HttpUtils {
  static toHttpParams(object?: object): HttpParams {
    let params = new HttpParams();

    if (object == null) {
      return params;
    }

    for (const key of Object.getOwnPropertyNames(object)) {
      params = HttpUtils.toHttpParam(params, key, object[key]);
    }

    return params;
  }

  static toHttpParam(params: HttpParams, key: string, value: any): HttpParams {
    if (value == null) {
      return params;
    }

    if (DateUtils.isLocalDate(value)) {
      value = DateUtils.localDateToDate(value);
    }

    if (value instanceof Date || TimeUtils.isTime(value)) {
      params = params.set(key, HttpUtils.toParamValue(value));
    } else if (Array.isArray(value)) {
      for (const item of value) {
        if (value == null) {
          continue;
        }

        params = HttpUtils.toHttpParam(params, `${key}`, item);
      }
    } else if (typeof value === 'object') {
      for (const objKey of Object.getOwnPropertyNames(value)) {
        if (value[objKey] == null) {
          continue;
        }
        params = HttpUtils.toHttpParam(params, `${key}.${objKey}`, value[objKey]);
      }
    } else {
      params = params.set(key, HttpUtils.toParamValue(value));
    }

    return params;
  }

  static toParamValue(value: any): string {
    if (value instanceof Date) {
      return DateUtils.formatDateAsDate(value);
    }
    if (TimeUtils.isTime(value)) {
      return TimeUtils.toString(value);
    }
    return value;
  }
}
