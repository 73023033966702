<div class="page-container">
  <h3 translate>generic.page-title.users-registry</h3>
  <div class="lds-dual-ring" *ngIf="false"></div>

  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/users" translate>menu.system-configuration.label</a></li>
      <li class="breadcrumb-item active" aria-current="page" translate>generic.page-title.users-registry</li>
    </ol>
  </nav>
</div>

<section>
  <ngb-tabset #t="ngbTabset" (tabChange)="changeTab($event)" type="pills">
    <ngb-tab title="{{ 'generic.section.user-detail' | translate }}" id="user">
      <ng-template ngbTabContent>
        <app-user-detail></app-user-detail>
      </ng-template>
    </ngb-tab>
    <ngb-tab title="{{ 'generic.section.user-profile' | translate }}" id="permissions" *ngIf="mode === 'edit'">
      <ng-template ngbTabContent>
        <app-user-permissions></app-user-permissions>
      </ng-template>
    </ngb-tab>
  </ngb-tabset>
</section>
