import {GeneralSettingEntity} from "../../models/dto/entities/general-setting-entity";
import {GeneralSetting} from "../../models/general-setting.model";
import {GeneralSettingValueEntity} from "../../models/dto/entities/general-setting-value-entity";
import {GeneralSettingValue} from "../../models/general-setting-value.model";
import {isNullOrUndefined} from "util";

function transformEntityToGeneralSetting(entity: GeneralSettingEntity): GeneralSetting {
    let model: GeneralSetting = new GeneralSetting();
    model = Object.assign(model, entity);
    return model;
}

function transformGeneralSettingToEntity(model: GeneralSetting): GeneralSettingEntity {
    let entity: GeneralSettingEntity = new GeneralSettingEntity();
    entity = Object.assign(entity, model);
    return entity;
}

function transformEntityToGeneralSettingValue(entity: GeneralSettingValueEntity): GeneralSettingValue {
    let model: GeneralSettingValue = new GeneralSettingValue();
    model = Object.assign(model, entity);
    return model;
}

function transformGeneralSettingValueToEntity(model: GeneralSettingValue): GeneralSettingValueEntity {
    let entity: GeneralSettingValueEntity = new GeneralSettingValueEntity();
    entity = Object.assign(entity, model);
    return entity;
}

export {transformEntityToGeneralSetting, transformGeneralSettingToEntity, transformEntityToGeneralSettingValue, transformGeneralSettingValueToEntity};
