<div class="page-container">
  <h3 translate>generic.page-title.society-registry</h3>
  <div class="lds-dual-ring" *ngIf="false"></div>

  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/companies" translate>menu.system-configuration.label</a></li>
      <li class="breadcrumb-item active" aria-current="page" translate>generic.page-title.society-registry</li>
    </ol>
  </nav>
</div>

<section *ngIf="initialized">
  <form [formGroup]="companyForm" class="form-horizontal">

    <div class="card my-4">
      <h5 class="card-header" translate>
        generic.section.registry
      </h5>

      <div class="card-body">
        <!-- ID -->
        <div class="form-group form-input-group form-inline row my-4" *ngIf="readonly">
          <label class="form-input-label col-md-2" translate>
            generic.field.code
          </label>
          <div class="col-md-6">
            <span>{{ data.code }}</span>
          </div>
        </div>

        <!--        Name -->
        <div class="form-group form-input-group form-inline row my-4">
          <label for="editNameText" class="form-input-label col-md-2" translate>
            generic.field.society-name
          </label>
          <div class="col-md-6">
            <input *ngIf="!readonly" type="text" maxlength="255" class="form-control form-input input-with-border"
                   formControlName="name"
                   [(ngModel)]="data.name"
                   [ngClass]="{ 'is-invalid': f.name.errors }"
                   name="editNameText" id="editNameText" required>
            <span *ngIf="readonly">{{ data.name }}</span>
            <div *ngIf="!readonly && f.name.errors" class="invalid-feedback">
              <div *ngIf="f.name.errors.required" translate>generic.message.validation.mandatory-error</div>
            </div>
          </div>
        </div>

        <!--        VAT -->
        <div class="form-group form-input-group form-inline row my-4">
          <label for="editVatNumberText" class="form-input-label col-md-2" translate>
            generic.field.vat-number
          </label>
          <div class="col-md-6">
            <input *ngIf="!readonly" type="text" maxlength="255" class="form-control form-input input-with-border"
                   formControlName="vatNumber"
                   [(ngModel)]="data.vatNumber"
                   [ngClass]="{ 'is-invalid': f.vatNumber.errors }"
                   name="editVatNumberText" id="editVatNumberText" required>
            <span *ngIf="readonly">{{ data.vatNumber }}</span>
            <div *ngIf="!readonly && f.vatNumber.errors" class="invalid-feedback">
              <div *ngIf="f.vatNumber.errors.required" translate>generic.message.validation.mandatory-error
              </div>
            </div>
          </div>
        </div>

        <!--        Address -->
        <div class="form-group form-input-group form-inline row my-4">
          <label for="editAddressText" class="form-input-label col-md-2" translate>
            generic.field.address
          </label>
          <div class="col-md-6">
            <input *ngIf="!readonly" type="text" maxlength="255" class="form-control form-input input-with-border"
                   formControlName="address"
                   [(ngModel)]="data.address"
                   [ngClass]="{ 'is-invalid': f.address.errors }"
                   name="editAddressText" id="editAddressText" required>
            <span *ngIf="readonly">{{ data.address }}</span>
            <div *ngIf="!readonly && f.address.errors" class="invalid-feedback">
              <div *ngIf="f.address.errors.required" translate>generic.message.validation.mandatory-error</div>
            </div>
          </div>
        </div>

        <!--        Country tax residence-->
        <div class="form-group form-input-group form-inline row my-4">
          <label for="editCountrySelect" class="form-input-label col-md-2" translate>
            generic.field.country-tax-residence
          </label>
          <div class="col-md-6">
            <ng-select *ngIf="!readonly" formControlName="country"
                       [ngClass]="{ 'is-invalid': f.country.errors }"
                       name="editCountrySelect"
                       id="editCountrySelect"
                       [(ngModel)]="data.countryTaxResidenceId"
                       class="custom"
                       [searchable]="true">
              <ng-option *ngFor="let country of countries"
                         [value]="country.id">{{ country.description}}</ng-option>
            </ng-select>
            <span *ngIf="readonly">{{ getCountryDesc(data.countryTaxResidenceId) }}</span>
            <div *ngIf="!readonly && f.country.errors" class="invalid-feedback">
              <div *ngIf="f.country.errors.required" translate>generic.message.validation.mandatory-error</div>
            </div>
          </div>
        </div>

        <!--        Country establishment -->
        <div class="form-group form-input-group form-inline row my-4">
          <label for="birthCountry" class="form-input-label col-md-2" translate>
            generic.field.country-establishment
          </label>
          <div class="col-md-6">
            <ng-select *ngIf="!readonly" formControlName="birthCountry"
                       [ngClass]="{ 'is-invalid': f.birthCountry.errors }"
                       name="birthCountry"
                       id="birthCountry"
                       [(ngModel)]="data.countryEstablishmentId"
                       class="custom"
                       [searchable]="false">
              <ng-option *ngFor="let country of countries"
                         [value]="country.id">{{ country.description}}</ng-option>
            </ng-select>
            <span *ngIf="readonly">{{ getCountryDesc(data.countryEstablishmentId) }}</span>
            <div *ngIf="!readonly && f.birthCountry.errors" class="invalid-feedback">
              <div *ngIf="f.birthCountry.errors.required" translate>generic.message.validation.mandatory-error
              </div>
            </div>
          </div>
        </div>

        <!--        Business type -->
        <div class="form-group form-input-group form-inline row my-4">
          <label for="editTypeSelect" class="form-input-label col-md-2" translate>
            generic.field.business-type
          </label>
          <div class="col-md-6">
            <ng-select *ngIf="!readonly" formControlName="type"
                       [ngClass]="{ 'is-invalid': f.type.errors }"
                       name="editTypeSelect"
                       id="editTypeSelect"
                       [(ngModel)]="data.typeBusinessAccordingId"
                       class="custom"
                       [searchable]="true">
              <ng-option *ngFor="let value of industries"
                         [value]="value.id">{{ value.description }}</ng-option>
            </ng-select>
            <span *ngIf="readonly">{{ getTypeBusinessAccordingDesc(data.typeBusinessAccordingId) }}</span>
            <div *ngIf="!readonly && f.type.errors" class="invalid-feedback">
              <div *ngIf="f.type.errors.required" translate>generic.message.validation.mandatory-error</div>
            </div>
          </div>
        </div>

        <!--        Business sector -->
        <div class="form-group form-input-group form-inline row my-4">
          <label for="editIndustrySelect" class="form-input-label col-md-2" translate>
            generic.field.business-selector
          </label>
          <div class="col-md-6">
            <ng-select *ngIf="!readonly" formControlName="industry"
                       [ngClass]="{ 'is-invalid': f.industry.errors }"
                       name="editIndustrySelect"
                       id="editIndustrySelect"
                       [(ngModel)]="data.mainBusinessSectorId"
                       class="custom"
                       [searchable]="true">
              <ng-option *ngFor="let values of businessSelector"
                         [value]="values.id">({{values.code}}) {{ values.description}}</ng-option>
            </ng-select>
            <span *ngIf="readonly">{{ getBusinessSelectorDesc(data.mainBusinessSectorId) }}</span>
            <div *ngIf="!readonly && f.industry.errors" class="invalid-feedback">
              <div *ngIf="f.industry.errors.required" translate>generic.message.validation.mandatory-error</div>
            </div>
          </div>
        </div>

        <!--        Is parent company? -->
        <div class="form-group form-input-group form-inline row my-4">
          <label for="isParentCompany" class="form-input-label col-md-2" translate>
            generic.field.is-parent-company
          </label>
          <div class="col-md-6">
            <ng-select *ngIf="!readonly && canEditParentCompany" formControlName="isParentCompany"
                       [ngClass]="{ 'is-invalid': f.isParentCompany.errors }"
                       name="isParentCompany"
                       id="isParentCompany"
                       class="custom"
                       [(ngModel)]="data.isParentCompany"
                       [searchable]="false">
              <ng-option *ngFor="let elem of yesnoList" [value]="elem.value"
                         translate>{{ elem.text}}</ng-option>

            </ng-select>
            <span *ngIf="(readonly || !canEditParentCompany) && data.isParentCompany === YesNoEnum.YES"
                  translate>generic.text.yes-answer</span>
            <span *ngIf="(readonly || !canEditParentCompany) && data.isParentCompany === YesNoEnum.NO"
                  translate>generic.text.no-answer</span>
            <div *ngIf="!readonly && canEditParentCompany && f.isParentCompany.errors" class="invalid-feedback">
              <div *ngIf="f.isParentCompany.errors.required" translate>
                generic.message.validation.mandatory-error
              </div>
            </div>
          </div>
        </div>

        <!--        Membership in group -->
        <div class="form-group form-input-group form-inline row my-4">
          <label for="editGroupSelect" class="form-input-label col-md-2" translate>
            generic.field.membership-in-group
          </label>
          <div class="col-md-6">
            <ng-select *ngIf="!readonly && canEditParentCompany" formControlName="group"
                       [ngClass]="{ 'is-invalid': f.group.errors }"
                       name="editGroupSelect"
                       id="editGroupSelect"
                       [(ngModel)]="data.membershipInGroup"
                       class="custom"
                       [searchable]="false">
              <ng-option *ngFor="let elem of booleanList" [value]="elem.value"
                         translate>{{ elem.text}}</ng-option>

            </ng-select>
            <span *ngIf="(readonly || !canEditParentCompany) && data.membershipInGroup" translate>generic.text.yes-answer</span>
            <span *ngIf="(readonly || !canEditParentCompany) && !data.membershipInGroup" translate>generic.text.no-answer</span>
            <div *ngIf="!readonly && canEditParentCompany && f.group.errors" class="invalid-feedback">
              <div *ngIf="f.group.errors.required" translate>generic.message.validation.mandatory-error</div>
            </div>
          </div>
        </div>

        <!--        Parent company -->
        <div class="form-group form-input-group form-inline row my-4"
             *ngIf="data.isParentCompany === YesNoEnum.NO && data.membershipInGroup">
          <label for="parentCompanyId" class="form-input-label col-md-2" translate>
            generic.field.parent-company
          </label>
          <div class="col-md-6">
            <ng-select *ngIf="!readonly && canEditParentCompany" formControlName="parentCompanyId"
                       [items]="parentCompanyList"
                       bindValue="id"
                       bindLabel="name"
                       [ngClass]="{ 'is-invalid': f.parentCompanyId.errors }"
                       name="parentCompanyId"
                       id="parentCompanyId"
                       [(ngModel)]="data.parentCompanyId"
                       class="custom"
                       [searchable]="false">
            </ng-select>
            <span
              *ngIf="readonly || !canEditParentCompany">{{ getParentCompanyDesc(data.parentCompanyId) }}</span>
            <div *ngIf="!readonly && canEditParentCompany && f.parentCompanyId.errors" class="invalid-feedback">
              <div *ngIf="f.parentCompanyId.errors.required" translate>
                generic.message.validation.mandatory-error
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <section class="my-3">
      <div class="row">
        <div class="col">
          <div class="d-flex flex-row p-1">
            <button class="btn btn-default mr-auto" (click)="goTolist()"
                    [disabled]="loading">{{ 'generic.action.back' | translate }}</button>
            <button class="btn btn-primary" *ngIf="!readonly" (click)="save()"
                    [disabled]="loading">{{ 'generic.action.save' | translate }}
            </button>
          </div>
        </div>
      </div>
    </section>
  </form>

</section>

<app-alert #alertModal [model]="" (onAnyCloseEmitter)="callbackApprovation($event)"></app-alert>
