import {Entity} from './entity';

export class SocietyEntity extends Entity {
    name: string;
    vatNumber: string;
    countryTaxResidenceId: string;
    address: string;
    countryEstablishmentId: string;
    typeBusinessAccordingId: string;
    mainBusinessSectorId: string;
    membershipInGroup: boolean;
    nameParentCompany: string;
    countryParentCompanyId: string;
}
