import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NavigationService} from 'src/app/services/navigation.service';
import {NavigationMenu} from 'src/app/enums/navigation-menu.enum';
import {AuthenticationService} from '@pwc/security';

@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.scss']
})
export class TeamsComponent implements OnInit {

  showLoading = true;
  permissions: string;

  constructor(private router: Router,
              private navigationService: NavigationService,
              private authenticationService: AuthenticationService
  ) {
  }

  ngOnInit(): void {
    this.showLoadingHandler(false);
    this.authenticationService.checkAdmin();
    this.permissions = 'rw';
    this.navigationService.setActiveMenu(NavigationMenu.SETTINGS);
  }

  showLoadingHandler(show: boolean = true) {
    this.showLoading = show;
  }

  openDetail(id: number, perm: string) {
    this.router.navigateByUrl('teams/' + id + '/' + perm);
  }

  openDetailHandler(obj: any) {
    // this.userName = $event;
    this.openDetail(obj.teamId, obj.permission);
  }

}
