import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-widget-communications',
  templateUrl: './widget-communications.component.html',
  styleUrls: ['./widget-communications.component.scss']
})
export class WidgetCommunicationsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
