import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Country} from '../models/country.model';
import {Traceability} from "../models/traceability.model";

@Injectable({
  providedIn: 'root'
})
export class TraceabilityService {

  constructor(private http: HttpClient) {
  }

  getAll() {
    return this.http.get<Traceability[]>(`${environment.apiUrl}/traceability`);
  }

    addTraceability(entity: Traceability) {
        return this.http.post(`${environment.apiUrl}/traceability`, entity);
    }
}
