import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {PageableService} from '@pwc/common';
import {PageableUserResource} from '../models/pageable-user-resource';

@Injectable({providedIn: 'root'})
export class UserPaginatedService extends PageableService<PageableUserResource, PageableUserResource> {
  protected baseUrl: string = environment.apiUrl;
  protected endpoint = 'users-paginated';

  convertResourceToModel(resource: PageableUserResource): PageableUserResource {
    return {...resource};
  }

  convertModelToResource(model: PageableUserResource): PageableUserResource {
    return {...model};
  }

  constructor(http: HttpClient) {
    super(http);
  }
}
