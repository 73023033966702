import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {Language} from '../models/language.model';
import {LanguageEnum} from "../enums/language.enum";

@Injectable({
  providedIn: 'root'
})
export class I18nService {
  public langSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public lang = this.langSubject.asObservable();
  public languages = [
    new Language('1', LanguageEnum.IT, 'IT'),
    new Language('2', LanguageEnum.EN, 'EN')
  ];

  constructor(private translate: TranslateService) {
  }
}
