<widget title="Ultime Comunicazioni" link="#" [contentRef]="widgetContent"></widget>

<ng-template #widgetContent>
  <div class="list-group list-group-flush">
    <a href="#" class="list-group-item list-group-item-action d-flex w-100 justify-content-start">
      <div class="pr-3 pb-3 align-self-center flex-shrink-1">
        <baloon></baloon>
      </div>
      <div class="d-flex w-100 justify-content-between">
        <div>
          <p class="notification-title">Avv. Andrea Arancio</p>
          <p>Grazie per la condivisione</p>
        </div>
        <small>13:40 PM</small>
      </div>
    </a>

    <a href="#" class="list-group-item list-group-item-action d-flex w-100 justify-content-start">
      <div class="pr-3 pb-3 align-self-center flex-shrink-1">
        <baloon color="#f3bd27"></baloon>
      </div>
      <div class="d-flex w-100 justify-content-between">
        <div>
          <p class="notification-title">Avv. Mauro Verde</p>
          <p>Grazie per la condivisione</p>
        </div>
        <small>13:40 PM</small>
      </div>
    </a>

    <a href="#" class="list-group-item list-group-item-action d-flex w-100 justify-content-start">
      <div class="pr-3 pb-3 align-self-center flex-shrink-1">
        <baloon color="#d8560a"></baloon>
      </div>
      <div class="d-flex w-100 justify-content-between">
        <div>
          <p class="notification-title">Avv. Maria Giallo</p>
          <p>Grazie per la condivisione</p>
        </div>
        <small>13:40 PM</small>
      </div>
    </a>
  </div>
</ng-template>
