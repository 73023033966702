import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  private cache: Map<String, any> = new Map<String, any>();

  public has(key: string): boolean {
    return this.cache.has(key);
  }

  public set(key: string, value: any): void {
    this.cache.set(key, value);
  }

  public get(key: string): any {
    return this.cache.get(key);
  }

  public forget(key: string): void {
    this.cache.delete(key);
  }
}
