import {BaseModel} from "./base-model";

export abstract class SelectableModel extends BaseModel {
  selected?: boolean = false;

  constructor(selected: boolean = false) {
    super();
    this.selected = selected;
  }
}
