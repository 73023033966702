<div class="page-container">
<!--  <div class="row mb-4">-->
<!--    <div class="col-sm-6 col-md-4">-->
<!--      <app-widget-communications></app-widget-communications>-->
<!--    </div>-->
<!--    <div class="col-sm-6 col-md-4">-->
<!--      <app-widget-recent-activity></app-widget-recent-activity>-->
<!--    </div>-->
<!--    <div class="col-sm-6 col-md-4">-->
<!--      <app-widget-upcoming-activity></app-widget-upcoming-activity>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div class="row">-->
<!--    <div class="col-sm-6 col-md-4">-->
<!--      <app-widget-publications></app-widget-publications>-->
<!--    </div>-->
<!--    <div class="col-sm-6 col-md-4">-->
<!--      <app-widget-news></app-widget-news>-->
<!--    </div>-->
<!--    <div class="col-sm-6 col-md-4">-->
<!--      <app-widget-communico></app-widget-communico>-->
<!--    </div>-->
<!--  </div>-->

<!--  <div class="mt-4 mb-4" *ngIf="false">-->
<!--    <div class="d-flex dashboard-icons-wrapper">-->
<!--      <div class="flex-fill">-->
<!--        <a href="#" class="d-flex dashboard-icon-box" style="background-color: #e03120;">-->
<!--          <img src="assets/images/icons/menu/fatturazione-elettronica.svg" class="icon-large" alt="">-->
<!--          <span>Fatturazione Elettronica</span>-->
<!--        </a>-->
<!--      </div>-->
<!--      <div class=" flex-fill">-->
<!--        <a href="#" class="d-flex dashboard-icon-box" style="background-color: #d8560a;">-->
<!--          <img src="assets/images/icons/menu/2086.svg" class="icon-large" alt="">-->
<!--          <span>2086 Digital Solution</span>-->
<!--        </a>-->
<!--      </div>-->
<!--      <div class="flex-fill">-->
<!--        <a href="#" class="d-flex dashboard-icon-box" style="background-color: #e78d15;">-->
<!--        <img src="assets/images/icons/menu/nota-spese.svg" class="icon-large" alt="">-->
<!--        <span>Gestione nota spese</span>-->
<!--        </a>-->
<!--      </div>-->
<!--      <div class="flex-fill">-->
<!--        <a href="#" class="d-flex dashboard-icon-box" style="background-color: #f3bd27;">-->
<!--        <img src="assets/images/icons/menu/incentivi.svg" class="icon-large" alt="">-->
<!--        <span>Incentivi governativi</span>-->
<!--        </a>-->
<!--      </div>-->
<!--      <div class="flex-fill">-->
<!--        <a href="#" class="d-flex dashboard-icon-box" style="background-color: #f1dc27;">-->
<!--        <img src="assets/images/icons/menu/documentale.svg" class="icon-large" alt="">-->
<!--        <span>Documentale</span>-->
<!--        </a>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
</div>
