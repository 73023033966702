import {Time} from '../models';
import {StringUtils} from './string-utils';

export class TimeUtils {
  static isTime(time: Time | object): time is Time {
    return (time as Time).hour !== undefined &&
      (time as Time).minute !== undefined;
  }

  static toString(time: Time): string {
    if (time == null) {
      return null;
    }

    if (time.hour == null || time.minute == null) {
      return null;
    }

    let hour = `${time.hour}`;
    let minute = `${time.minute}`;

    if (time.hour < 10) {
      hour = `0${time.hour}`;
    }
    if (time.minute < 10) {
      minute = `0${time.minute}`;
    }

    return `${hour}:${minute}`;
  }

  static toTime(string: string): Time {
    const empty = {
      hour: null,
      minute: null,
    };

    if (StringUtils.isEmpty(string)) {
      return empty;
    }

    if (string.indexOf(':') === -1) {
      return empty;
    }

    const [hour, minute] = string.split(':');
    return {
      hour: isNaN(+hour) ? null : +hour,
      minute: isNaN(+minute) ? null : +minute
    };
  }
}
