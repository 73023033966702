<div class="page-container">
  <h3 translate>generic.page-title.society-registry</h3>
  <div class="lds-dual-ring" *ngIf="showLoading" ></div>

  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/companies" translate>menu.system-configuration.label</a></li>
      <li class="breadcrumb-item active" aria-current="page" translate>generic.page-title.society-registry</li>
    </ol>
  </nav>

  <app-companies-list
    (loadingEvent)="showLoadingHandler()"
    (openDetail)="openDetailHandler($event)"></app-companies-list>
</div>
