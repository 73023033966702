export class Entity {
  id: number;
  version?: number;
  sequenceKey?: string;
  flagValid: boolean = true;
  flagDeleted?: boolean;
  createdBy?: string;
  createdDate?: string;
  lastModifiedBy?: string;
  lastModifiedDate?: string;
  flagActive?: boolean;
}
