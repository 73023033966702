<div class="page-container">
  <h3 translate>generic.page-title.team-registry</h3>
  <div class="lds-dual-ring" *ngIf="showLoading" ></div>

  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/teams" translate>menu.system-configuration.label</a></li>
      <li class="breadcrumb-item active" aria-current="page" translate>generic.page-title.team-registry</li>
    </ol>
  </nav>

  <app-teams-list (openDetail)="openDetailHandler($event)">


  </app-teams-list>
</div>
