<ngx-smart-modal #alertModal identifier="alertModal" customClass="nsm-dialog-animation-ttb modal-content documents-uploader-modal p-0" (onAnyCloseEvent)="onAnyClose($event)" force="false">
  <div class="modal-header">
    <h5 class="modal-title text-uppercase">
      {{ getHeaderMessage() }}
    </h5>
  </div>
  <div class="modal-body">
    <div *ngIf="alertModal.hasData()" class="mb-4 mt-4 message" [innerHTML]="alertModal.getData().message" style="color:#000000"></div>

  </div>
  <div class="modal-footer d-flex justify-content-end">
    <button class="btn btn-primary mr-2" (click)="alertModal.close()">
      {{ 'generic.action.ok' | translate }}
    </button>
  </div>
</ngx-smart-modal>
