import {PaginationInfo} from './pagination-info';

export class PageableResponse<T> {
  content: T;
  pageable?: PaginationInfo;
  totalElements?: number;
  size?: number;
  number?: number;
  numberOfElements?: number;
}
