<form [formGroup]="userForm" class="form-horizontal" *ngIf="initialized">
  <div class="card my-4">
    <h5 class="card-header" translate>
      generic.section.registry
    </h5>

    <div class="card-body">

      <div class="form-group form-input-group form-inline row my-4">
        <label for="userTypeSelect" class="form-input-label col-md-2" translate>
          generic.field.user-type
        </label>
        <div class="col-md-6">
          <ng-select *ngIf="!readonly()" formControlName="userType"
                     [ngClass]="{ 'is-invalid': f.userType.errors }"
                     name="userTypeSelect"
                     id="userTypeSelect"
                     class="custom"
                     [searchable]="true">
              <ng-option value="BANKING_OPERATOR">{{'generic.user-types.bank-operator'|translate}}</ng-option>
              <ng-option value="PRIVATE_CONDOMINIUM">{{'generic.user-types.private'|translate}}</ng-option>
              <ng-option value="COMPANY">{{'generic.user-types.company'|translate}}</ng-option>
              <ng-option value="PARTNER">{{'generic.user-types.partner'|translate}}</ng-option>
              <ng-option value="PARTNER_ADVISORY">{{'generic.user-types.partner-advisory'|translate}}</ng-option>
              <ng-option value="TECH_OPERATOR">{{'generic.user-types.tech-op'|translate}}</ng-option>
              <ng-option value="PWC_OPERATOR">{{'generic.user-types.pwc-op'|translate}}</ng-option>
              <ng-option value="DELEGATE">{{'generic.user-types.delegate'|translate}}</ng-option>
          </ng-select>
          <span *ngIf="readonly()">{{ data.userType }}</span>
          <div *ngIf="!readonly() && f.userType.errors" class="invalid-feedback">
            <div *ngIf="f.userType.errors.required" translate>generic.message.validation.mandatory-error</div>
          </div>
        </div>
      </div>


      <!--      Last name -->
      <div class="form-group form-input-group form-inline row my-4" >
        <label for="editLastNameText" class="form-input-label col-md-2" translate>
          generic.field.last-name
        </label>
        <div class="col-md-6">
          <input *ngIf="!readonly()" type="text" maxlength="255" class="form-control form-input input-with-border"
                 formControlName="lastName"
                 [ngClass]="{ 'is-invalid': f.lastName.errors }"
                 id="editLastNameText"
                 name="editLastNameText" required>
          <span *ngIf="readonly()">{{ data.lastName }}</span>
          <div *ngIf="!readonly() && f.lastName.errors" class="invalid-feedback">
            <div *ngIf="f.lastName.errors.required" translate>generic.message.validation.mandatory-error</div>
          </div>
        </div>
      </div>


      <!--      First name -->
      <div class="form-group form-input-group form-inline row my-4">
        <label for="editFirstNameText" class="form-input-label col-md-2" translate>
          generic.field.first-name
        </label>
        <div class="col-md-6">
          <input *ngIf="!readonly()" type="text" maxlength="255" class="form-control form-input input-with-border"
                 formControlName="firstName"
                 [ngClass]="{ 'is-invalid': f.firstName.errors }"
                 id="editFirstNameText"
                 name="editFirstNameText" required>
          <span *ngIf="readonly()">{{ data.firstName }}</span>
          <div *ngIf="!readonly() && f.firstName.errors" class="invalid-feedback">
            <div *ngIf="f.firstName.errors.required" translate>generic.message.validation.mandatory-error</div>
          </div>
        </div>
      </div>

      <!--      Company name -->
      <div class="form-group form-input-group form-inline row my-4" *ngIf="f.userType.value=='COMPANY'">
        <label for="editCompanyNameText" class="form-input-label col-md-2" translate>
          generic.field.company-name
        </label>
        <div class="col-md-6">
          <input *ngIf="!readonly()" type="text" maxlength="255" class="form-control form-input input-with-border"
                formControlName="companyName"
                [ngClass]="{ 'is-invalid': f.companyName.errors }"
                id="editCompanyNameText"
                name="editCompanyNameText" >
          <span *ngIf="readonly()">{{ data.companyName }}</span>
          <div *ngIf="!readonly() && f.companyName.errors" class="invalid-feedback">
            <div *ngIf="f.companyName.errors.required" translate>generic.message.validation.mandatory-error</div>
          </div>
        </div>
      </div>
      <!--      Fiscal code -->
      <div class="form-group form-input-group form-inline row my-4">
        <label for="fiscalCode" class="form-input-label col-md-2" translate>
          generic.field.fiscal-code
        </label>
        <div class="col-md-6">
          <input *ngIf="!readonly()" type="text" maxlength="100" class="form-control form-input input-with-border"
                 formControlName="fiscalCode"
                 name="fiscalCode" id="fiscalCode" [attr.required]="f.vat.value==null || f.vat.value==''">
          <span *ngIf="readonly()">{{ data.fiscalCode }}</span>
          <div *ngIf="!readonly() && (f.vat.value==null || f.vat.value=='') && (f.fiscalCode.value==null || f.fiscalCode.value=='')" class="invalid-feedback">
            <div *ngIf="(f.vat.value==null || f.vat.value=='') && (f.fiscalCode.value==null || f.fiscalCode.value=='')" translate>generic.message.validation.mandatory-error</div>
          </div>
        </div>
      </div>


      <!--      vat number -->
      <div class="form-group form-input-group form-inline row my-4">
        <label for="vat" class="form-input-label col-md-2" translate>
          generic.field.vat
        </label>
        <div class="col-md-6">
          <input *ngIf="!readonly()" type="text" maxlength="100" class="form-control form-input input-with-border"
                formControlName="vat"
                name="vat" id="vat"  [attr.required]="f.fiscalCode.value==null || f.fiscalCode.value==''">
          <span *ngIf="readonly()">{{ data.vat }}</span>
          <div *ngIf="!readonly() && (f.vat.value==null || f.vat.value=='') && (f.fiscalCode.value==null || f.fiscalCode.value=='')" class="invalid-feedback">
            <div *ngIf="(f.vat.value==null || f.vat.value=='') && (f.fiscalCode.value==null || f.fiscalCode.value=='')" translate>generic.message.validation.mandatory-error</div>
          </div>
        </div>
      </div>

      <!--      Email -->
      <div class="form-group form-input-group form-inline row my-4">
        <label for="editEmailText" class="form-input-label col-md-2" translate>
          generic.field.email
        </label>
        <div class="col-md-6">
          <input *ngIf="!readonly()" type="email" class="form-control form-input input-with-border"
                 formControlName="email"
                 [ngClass]="{ 'is-invalid': f.email.errors }"
                 id="editEmailText"
                 name="editEmailText" required>
          <span *ngIf="readonly()">{{ data.email }}</span>
          <div *ngIf="!readonly() && f.email.errors" class="invalid-feedback">
            <div *ngIf="f.email.errors.required" translate>generic.message.validation.mandatory-error</div>
            <div *ngIf="f.email.errors.email" translate>generic.message.validation.not-valid-field</div>
          </div>
        </div>
      </div>

    <!--      Phone -->
    <div class="form-group form-input-group form-inline row my-4">
      <label for="editPhoneText" class="form-input-label col-md-2" translate>
        generic.field.phone
      </label>
      <div class="col-md-6">
        <input *ngIf="!readonly()" type="text" class="form-control form-input input-with-border"
              formControlName="phone"
              [ngClass]="{ 'is-invalid': f.phone.errors }"
              id="editPhoneText"
              name="editPhoneText" >
        <span *ngIf="readonly()">{{ data.phone }}</span>
        <div *ngIf="!readonly() && f.phone.errors" class="invalid-feedback">
          <div *ngIf="f.phone.errors.required" translate>generic.message.validation.mandatory-error</div>
          <div *ngIf="f.emphoneil.errors.email" translate>generic.message.validation.not-valid-field</div>
        </div>
      </div>
    </div>



    </div>
  </div>

  <div class="card my-4">
    <h5 class="card-header" translate>
      generic.section.access
    </h5>
    <div class="card-body">
      <!-- Language -->
      <div class="form-group form-input-group form-inline row my-4">
        <label for="editLangSelect" class="form-input-label col-md-2" translate>
          generic.field.language
        </label>
        <div class="col-md-6">
          <ng-select *ngIf="!readonly()" formControlName="language"
                     [ngClass]="{ 'is-invalid': f.language.errors }"
                     name="editLangSelect"
                     id="editLangSelect"
                     class="custom"
                     [searchable]="true">
            <ng-option *ngFor="let lang of langList"
                       [value]="lang.code">{{ lang.description}}</ng-option>
          </ng-select>
          <span *ngIf="readonly()">{{ data.language }}</span>
          <div *ngIf="!readonly() && f.language.errors" class="invalid-feedback">
            <div *ngIf="f.language.errors.required" translate>generic.message.validation.mandatory-error</div>
          </div>
        </div>
      </div>

      <!--      Enabled -->
      <div class="form-group form-input-group form-inline row my-4">
        <label for="filterEnabledSelect" class="form-input-label col-md-2" translate>
          generic.field.enabled
        </label>
        <div class="col-md-6">
          <ng-select *ngIf="!readonly()" formControlName="enabled"
                     id="filterEnabledSelect"
                     name="filterEnabledSelect"
                     [clearable]="false"
                     class="custom"
                     [searchable]="false">
            <ng-option *ngFor="let elem of [ { value: true }, { value: false } ]"
                       [value]="elem.value">{{ elem.value | booleanPipe}}</ng-option>
          </ng-select>
          <span *ngIf="readonly()">{{ data.enabled | booleanPipe }}</span>
        </div>
      </div>

      <!--      Username -->
      <div class="form-group form-input-group form-inline row my-4">
        <label for="editUsernameText" class="form-input-label col-md-2" translate>
          generic.field.username
        </label>
        <div class="col-md-6">
          <input *ngIf="!readonly()" type="text" maxlength="255" class="form-control form-input input-with-border"
                 formControlName="username"
                 [ngClass]="{ 'is-invalid': f.username.errors }"
                 id="editUsernameText"
                 name="editUsernameText" required>
          <span *ngIf="readonly()">{{ data.username }}</span>
          <div *ngIf="!readonly() && f.username.errors" class="invalid-feedback">
            <div *ngIf="f.username.errors.required" translate>generic.message.validation.mandatory-error</div>
          </div>
        </div>
      </div>

      <!--      Password strategy -->
      <div class="form-group form-input-group form-inline row my-4">
        <label for="passwordStrategy" class="form-input-label col-md-2" translate>
          generic.field.password-strategy
        </label>
        <div class="col-md-6">
          <ng-select *ngIf="!readonly()"
                     formControlName="passwordStrategy"
                     [ngClass]="{ 'is-invalid': submitted && f.passwordStrategy.errors }"
                     name="passwordStrategy"
                     id="passwordStrategy"
                     class="custom"
                     [searchable]="false">
            <ng-option *ngFor="let strategy of passwordStrategies"
                       [value]="strategy.id">{{ strategy.label | translate }}</ng-option>
          </ng-select>
          <div *ngIf="!readonly() && submitted && f.passwordStrategy.errors" class="invalid-feedback">
            <div *ngIf="f.passwordStrategy.errors.required" translate>
              generic.message.validation.mandatory-error
            </div>
          </div>
        </div>
      </div>

      <ng-container
        *ngIf="f.passwordStrategy.value != null && f.passwordStrategy.value === PasswordStrategyCodeEnum.MANUAL">
        <!--      Password -->
        <div class="form-group form-input-group form-inline row my-4">
          <label for="password" class="form-input-label col-md-2" translate>
            generic.field.password
          </label>
          <div class="col-md-6">
            <input *ngIf="!readonly()"
                   type="password"
                   class="form-control form-input input-with-border"
                   formControlName="password"
                   id="password"
                   name="password"
                   [ngClass]="{ 'is-invalid': submitted && ((mode === 'edit' && f.passwordStrategy.value !== '') || (mode==='new'))  && f.password.errors }"
                   required>
            <span *ngIf="readonly()">{{ data.password }}</span>
            <div *ngIf="!readonly() && f.password.value !== ''" class="my-2">
              <pwc-password-meter [password]="f.password.value"></pwc-password-meter>
            </div>
            <div
              *ngIf="!readonly() && ((mode === 'edit' && f.passwordStrategy.value !== '') || (mode==='new')) && submitted && f.password.errors !== null"
              class="invalid-feedback">
              <div *ngIf="f.password.errors.required" translate>generic.message.validation.mandatory-error</div>
              <div *ngIf="f.password.errors.minlength" translate>
                generic.message.validation.password-min-length
              </div>
            </div>
          </div>
          <div class="info-wrapper">
            <pwc-icon name="info" [ngbTooltip]="pwdRules"></pwc-icon>
          </div>
        </div>

        <!--        Password confirmation -->
        <div class="form-group form-input-group form-inline row my-4">
          <label for="editPasswordConfirmText" class="form-input-label col-md-2" translate>
            generic.field.password-confirm
          </label>
          <div class="col-md-6">
            <input *ngIf="!readonly()" type="password" class="form-control form-input input-with-border"
                   formControlName="confirmPassword"
                   [ngClass]="{ 'is-invalid': f.confirmPassword.errors }"
                   id="editPasswordConfirmText"
                   name="editPasswordConfirmText" required>
            <span *ngIf="readonly()">{{ data.confirmPassword }}</span>
            <div *ngIf="!readonly() && f.confirmPassword.errors" class="invalid-feedback">
              <div *ngIf="f.confirmPassword.errors.required" translate>
                generic.message.validation.mandatory-error
              </div>
              <div *ngIf="f.confirmPassword.errors.mustMatch" translate>
                generic.message.validation.password-confirm-check
              </div>
            </div>
          </div>
          <div class="info-wrapper">
            <pwc-icon name="info" [ngbTooltip]="pwdRules"></pwc-icon>
          </div>
        </div>

        <!--      Require password change-->
        <div class="form-group form-input-group form-inline row my-4">
          <label for="requirePasswordChange" class="form-input-label col-md-2" translate>
            generic.field.requirePasswordChange
          </label>
          <div class="col-md-6">
            <ng-select *ngIf="!readonly()"
                       formControlName="requirePasswordChange"
                       id="requirePasswordChange"
                       name="requirePasswordChange"
                       [clearable]="false"
                       class="custom"
                       [searchable]="false">
              <ng-option *ngFor="let elem of [ { value: true }, { value: false } ]"
                         [value]="elem.value">{{ elem.value | booleanPipe}}</ng-option>
            </ng-select>
            <span *ngIf="readonly()">{{ data.requirePasswordChange | booleanPipe }}</span>
          </div>
        </div>

      </ng-container>

      <!--      Teams -->
      <div class="form-group form-input-group form-inline row my-4">
        <label for="groupsSelect" class="form-input-label col-md-2" translate>
          generic.field.team
        </label>
        <div class="col-md-6">
          <ng-select *ngIf="!readonly()"
                     name="groupsSelect"
                     id="groupsSelect"
                     formControlName="teams"
                     [items]="teams"
                     bindValue="id"
                     bindLabel="teamName"
                     [(ngModel)]="selectedTeams"
                     class="custom"
                     [closeOnSelect]="false"
                     [multiple]="true"
                     [searchable]="true">
          </ng-select>
          <span *ngIf="readonly()">{{getTeamNames()}}</span>
        </div>
      </div>


    </div>
  </div>

  <section *ngIf="false">
    <div class="row">
      <div class="col">
        <div class="card index-data-card">
          <div class="card-body">
            <h5 class="card-title" translate>generic.section.modules</h5>
            <div class="card-text">

            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--  <section>-->
  <!--    <div class="row">-->
  <!--      <div class="col">-->
  <!--        <div class="d-flex flex-row p-1">-->
  <!--          <button class="btn btn-undo mr-auto" (click)="goTolist()"-->
  <!--                  [disabled]="loading">{{ 'generic.action.back' | translate }}</button>-->
  <!--          <button class="btn btn-primary" *ngIf="!readonly()" (click)="save()"-->
  <!--                  [disabled]="loading">{{ 'generic.action.save' | translate }}-->
  <!--          </button>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </section>-->

  <div class="d-flex justify-content-end my-4">
    <button type="button" class="btn btn-default mx-2" (click)="goTolist()" [disabled]="loading" translate>
      generic.action.back
    </button>
    <button type="button" class="btn btn-primary mx-2" *ngIf="!readonly()" (click)="save()" [disabled]="loading"
            translate>
      generic.action.save
    </button>
  </div>

</form>

<app-alert #alertModal [model]="" (onAnyCloseEmitter)="callbackApprovation($event)"></app-alert>

<ng-template #pwdRules>
  <p class="mb-0 text-left" style="color:#fff;">La password deve rispettare i seguenti criteri:</p>
  <ul class="pl-2 ml-0 text-left">
    <li>lunghezza minima di 8 caratteri</li>
    <li>deve contenere lettere minuscole e maiuscole, numeri e caratteri speciali</li>
    <li>non deve contenere nome o cognome dell'utente, username o nomi scontati (admin, password, 123, etc)</li>
    <li>deve essere diversa dalle ultime 10 password utilizzate</li>
  </ul>
</ng-template>
