import {NgModule} from '@angular/core';
import {CommonModule as AngularCommonModule} from '@angular/common';
import {WidgetComponent} from './widget/widget.component';
import { BaloonComponent } from './baloon/baloon.component';

@NgModule({
  declarations: [
    WidgetComponent,
    BaloonComponent
  ],
  imports: [
    AngularCommonModule
  ],
  exports: [
    WidgetComponent,
    BaloonComponent
  ]
})
export class CommonModule {
}
