export class StringUtils {
  static isEmpty(string: string): boolean {
    return string == null || `${string}`.trim() === '';
  }

  static toAsciiNumber(string: string): number {
    let number = '';
    const length = string.length;

    for (let i = 0; i < length; i++) {
      number += string.charCodeAt(i).toString(10);
    }

    return parseInt(number);
  }

  static fromAsciiNumber(ascii: number): string {
    let string = '';
    const numeric = `${ascii}`;
    const length = numeric.length;

    for (let i = 0; i < length;) {
      const code = numeric.slice(i, i += 2);
      string += String.fromCharCode(parseInt(code, 10));
    }

    return string;
  }
}
