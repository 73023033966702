import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {DocumentClass} from '../models/document-class.model';
import {Role} from "../models/role.model";

@Injectable({
  providedIn: 'root'
})
export class DocumentClassService {

  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<DocumentClass[]>(`${environment.apiUrl}/document-classes`);
  }

    getUserDocClasses(idUser: number, idSociety: number) {
        return this.http.get<Role[]>(`${environment.apiUrl}/users/${idUser}/roles/${idSociety}?type=CLASSE_DOCUMENTALE`);
    }
}
