import {LanguageEnum} from '../enums/language.enum';
import {BaseModel} from "./base-model";

export class Translation extends BaseModel {

  name: string;
  description: string;
  value: string;
  translationKey: LanguageEnum;

  constructor(name?: string, description?: string, value?: string, translationKey?: LanguageEnum) {
    super();
    this.name = name;
    this.description = description;
    this.translationKey = translationKey;
    this.value = value;
  }
}
