import {TraceabilityProcessEnum} from "../enums/traceability-process.enum";
import {Company} from "./company.model";

export class Traceability {
    id: number;
    user: any;
    userId: number;
    groupsEntities: string;
    date: number;
    process: TraceabilityProcessEnum;
    processDescription: string
    activity: string;
    societyId: number;
    society: Company;

    constructor(id?: number, user?: any, userId?: number, societyId?: number, society?: Company, date?: number, process?: TraceabilityProcessEnum, processDescription?: string, activity?: string, groupsEntities?: string) {
        this.id = id;
        this.user = user;
        this.userId = userId;
        this.date = date;
        this.process = process;
        this.activity = activity;
        this.society = society;
        this.processDescription = processDescription;
        this.groupsEntities = groupsEntities;
    }
}
