import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Role} from '../models/role.model';

@Injectable({
  providedIn: 'root'
})
export class RolesService {

  constructor(private http: HttpClient) {
  }

  getAll() {
    return this.http.get<Role[]>(`${environment.apiUrl}/roles`);
  }

    getUserPermissions(idUser: number, idSociety: number) {
        return this.http.get<Role[]>(`${environment.apiUrl}/users/${idUser}/roles/${idSociety}?type=PRIMARY`);
    }

    editRole(idUser: number, idSociety: number, roles: Role[]) {
        return this.http.post(`${environment.apiUrl}/users/${idUser}/roles/${idSociety}`, roles);
    }
}
