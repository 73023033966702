import {Team} from '../components/teams/models/team';
import {PasswordStrategyCodeEnum} from '../enums/password-strategy-code.enum';
import {UserTypeEnum} from '@pwc/common/enum/user-type.enum';
import {SelectableModel} from '@pwc/common';

export class User extends SelectableModel {
  id: number;
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  fiscalCode?: string;
  email?: string;
  token?: string;
  language: string;
  enabled?: boolean = true;
  flagValid: boolean;
  passwordExpire: Date;
  teamUsers: TeamUser[] = [];
  passwordStrategy?: PasswordStrategyCodeEnum = null;
  requirePasswordChange?: boolean = false;
  userType?: UserTypeEnum;
  vat?: string;
  companyName?: string;
  phone?: string;
  userInfoEcobonus?: any;
  mfaEnabled?: boolean;
  origin?: string;

  pageable?: any;
  page?: number;
  size?: number;

  constructor(id?: number, username?: string, password?: string, firstName?: string, lastName?: string, fiscalCode?: string, language?: string, email?: string, token?: string, enabled?: boolean, flagValid?: boolean, passwordExpire?: any, passwordStrategy?: PasswordStrategyCodeEnum,
              userType?: UserTypeEnum, vat?: string, companyName?: string, phone?: string, userInfoEcobonus?: any) {
    super();
    this.id = id;
    this.username = username;
    this.password = password;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.fiscalCode = fiscalCode;
    this.token = token;
    this.enabled = enabled == null ? true : enabled;
    this.language = language;
    this.flagValid = flagValid;
    this.passwordExpire = passwordExpire;
    this.passwordStrategy = passwordStrategy;
    this.teamUsers = [];
    this.userType = userType;
    this.vat = vat;
    this.companyName = companyName;
    this.phone = phone;
    this.userInfoEcobonus = userInfoEcobonus;
  }
}

export class TeamUser {
  user?: User;
  userId?: number;
  team?: Team;
  teamId?: number;
}

