import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {RolesService} from '../../../services/roles.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {User} from '../../../models/user.model';
import {Role} from '../../../models/role.model';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../../services/user.service';
import {DocumentClassService} from '../../../services/document-class.service';
import {CompanyService} from '../../../services/company.service';
import {Company} from '../../../models/company.model';
import {isNullOrUndefined} from 'util';
import {LocalTranslateEnum} from '../../../enums/local-translate.enum';
import {TranslateService} from '@ngx-translate/core';
import {YesNoEnum} from '../../../enums/yes-no.enum';
import {Authorities} from '../../../enums/security/authorities.enum';

@Component({
  selector: 'app-user-permissions',
  templateUrl: './user-permissions.component.html',
  styleUrls: ['./user-permissions.component.scss']
})
export class UserPermissionsComponent implements OnInit {
  filterForm: FormGroup;
  id: number;
  permissions: string;
  mode: string;
  user: User;
  roles: Role[] = [];
  documentClasses: Role[] = [];
  allCompanies: Company[] = [];
  companies: Company[] = [];
  parentCompanies: Company[] = [];
  data: { parentCompany: number, company: number } = {parentCompany: 0, company: 0};
  loading: boolean = false;
  submitted: boolean = false;
  isAdmin: boolean = false;

  @ViewChild('alertModalPerm', {static: true}) alertModal: any;

  tranlastionKeys = [
    LocalTranslateEnum.errorSaving,
    LocalTranslateEnum.errorLoading,
    LocalTranslateEnum.deleteConfirm,
    LocalTranslateEnum.deleteSelectedConfirm,
    LocalTranslateEnum.deleteSuccess,
    LocalTranslateEnum.savingSuccess,
    LocalTranslateEnum.errorGeneric,
    LocalTranslateEnum.usersOwnerActivity,
    LocalTranslateEnum.validationFieldDuplicated
  ];
  messages = {
    errorSaving: '',
    errorLoading: '',
    deleteConfirm: '',
    deleteSelectedConfirm: '',
    deleteSuccess: '',
    savingSuccess: '',
    usersOwnerActivity: '',
    errorGeneric: '',
    validationFieldDuplicated: ''
  };

  requiredAuthorities = {
    company: {
      read: Authorities.ROLE_COMPANY_READ,
      create: Authorities.ROLE_COMPANY_CREATE,
      update: Authorities.ROLE_COMPANY_UPDATE,
      delete: Authorities.ROLE_COMPANY_DELETE,
    },
    parentCompany: {
      read: Authorities.ROLE_PARENT_COMPANY_READ,
      create: Authorities.ROLE_PARENT_COMPANY_CREATE,
      update: Authorities.ROLE_PARENT_COMPANY_UPDATE,
      delete: Authorities.ROLE_PARENT_COMPANY_DELETE,
    },
    roleManager: {
      super_user: Authorities.ROLE_SUPER_USER_MANAGER
      // basic_office: Authorities.ROLE_BASIC_OFFICER_MANAGER,
      // reader: Authorities.ROLE_READER_MANAGER,
      // approver: Authorities.ROLE_APPROVER_MANAGER,
      // head_officer: Authorities.ROLE_HEAD_OFFICER_MANAGER
    },
    highPrivilegeGroups: [
      Authorities.ROLE_USER_GROUP_ADMIN
    ]
  };

  constructor(private rolesService: RolesService,
              private documentClassService: DocumentClassService,
              private router: Router,
              private route: ActivatedRoute,
              private formBuilder: FormBuilder,
              private userService: UserService,
              private companyService: CompanyService,
              private translateService: TranslateService,
              private changeDetector: ChangeDetectorRef) {
    this.initTranslations();
  }

  initTranslations() {
    if (isNullOrUndefined(this.translateService)) {
      return;
    }

    this.translateService.get(this.tranlastionKeys).subscribe(translated => {
      this.messages.errorSaving = translated[LocalTranslateEnum.errorSaving];
      this.messages.errorLoading = translated[LocalTranslateEnum.errorLoading];
      this.messages.deleteConfirm = translated[LocalTranslateEnum.deleteConfirm];
      this.messages.deleteSelectedConfirm = translated[LocalTranslateEnum.deleteSelectedConfirm];
      this.messages.deleteSuccess = translated[LocalTranslateEnum.deleteSuccess];
      this.messages.savingSuccess = translated[LocalTranslateEnum.savingSuccess];
      this.messages.errorGeneric = translated[LocalTranslateEnum.errorGeneric];
      this.messages.usersOwnerActivity = translated[LocalTranslateEnum.usersOwnerActivity];
      this.messages.validationFieldDuplicated = translated[LocalTranslateEnum.validationFieldDuplicated];
      // console.log('this.messages', this.messages);
    });
  }

  ngOnInit() {
    if (this.router.url.endsWith('/new')) {
      this.mode = 'new';
    } else {
      this.user = new User();
      this.loading = true;
      this.mode = 'edit';
      this.route.params.subscribe(params => {
        // console.log('params', params);
        this.id = +params.id; // (+) converts string 'id' to a number
        this.permissions = params.permissions;
        // load user
        this.userService.getUser(this.id).subscribe(user => {
          this.user = user;
          this.loading = false;
          this.initValidation();
        }, (err) => {
          // console.log(err);
          this.id = 0;
          this.openAlert(0, 'error', this.messages.errorLoading);
          this.loading = false;
          // this.goTolist();
        });

        this.companyService.isAdmin.subscribe(auth => {
          this.isAdmin = auth;
          if (auth) {
            this.companyService.getParentCompanies().subscribe(companies => this.parentCompanies = companies);
            this.companyService.getAll().subscribe(companies => {
              this.allCompanies = companies;
              this.companies = companies;
            });
          } else {
            this.userService.getSocieties(null, true)
              .subscribe((companies) => {
                this.allCompanies = companies;
                this.companies = companies;
                this.parentCompanies = companies.filter(c => c.isParentCompany === YesNoEnum.YES);
                this.data.parentCompany = this.parentCompanies.length > 0 ? this.parentCompanies[0].id : null;
              });
          }
        });
      });
    }
  }

  private initValidation() {
    this.filterForm = this.formBuilder.group({
      editCompanySelect: ['', Validators.required]
    });
  }

  goTolist() {
    this.router.navigateByUrl('users');
  }

  goToUser() {
    // console.log('id', this.id);
    this.router.navigateByUrl(`/users/${this.id}/${this.permissions}`);
  }

  save(goToListAfterSaved: boolean = true) {
    this.submitted = true;
    if (!this.showDocumentClass || !this.validationFailed()) {
      this.loading = true;
      this.rolesService.editRole(this.user.id, this.data.company, this.documentClasses.concat(this.roles)).subscribe(res => {
        // this.roles = roles;
        // this.goTolist();
        this.openAlert(0, goToListAfterSaved ? 'success-gotolist' : 'success', this.messages.savingSuccess);
        this.loading = false;
        // console.log('RES', res);
      }, (err) => {
        // console.log(err);
        this.openAlert(0, 'success', this.messages.errorSaving);
        this.loading = false;
      });
    }
  }

  triggerChangeDetection(group, checked) {
    // console.log('change', group, checked);

    if (group === 'BASIC_OFFICER') {
      if (checked) {
        this.documentClasses.filter(d => d.selected = false);
        this.submitted = false;
      }
    } else if (this.roles.filter(r => r.selected).length > 0) {
      // console.log('add class doc',this.roles);
      const role = this.roles.find(role => role.groupCode.toUpperCase() === 'BASIC_OFFICER');
      if (isNullOrUndefined(role) || !role.selected) {
        this.documentClasses.filter(d => d.selected = true);
      }
    } else {
      this.documentClasses.filter(d => d.selected = false);
    }

    this.changeDetector.markForCheck();
  }

  loadPermissions() {
    if (this.companies.length > 0) {
      this.submitted = false;
      this.roles = [];
      this.documentClasses = [];
      if (isNullOrUndefined(this.data.company)) {
        return;
      }
      if (!isNullOrUndefined(this.allCompanies.filter(c => c.id === this.data.company)[0].parentCompanyId)) {
        this.data.parentCompany = this.allCompanies.filter(c => c.id === this.data.company)[0].parentCompanyId;
      } else {
        this.data.parentCompany = null;
      }
      this.rolesService.getUserPermissions(this.user.id, this.data.company).subscribe(roles => {
        this.roles = roles;
      });
      this.documentClassService.getUserDocClasses(this.user.id, this.data.company).subscribe(documentClasses => {
        this.documentClasses = documentClasses;
      });
    }
  }

  filterSocietis() {
    // filter child company
    this.companies = this.allCompanies.filter(c => isNullOrUndefined(this.data.parentCompany) || c.parentCompanyId === this.data.parentCompany);
    // if exists, add parent company
    this.companies = [...this.allCompanies.filter(c => !isNullOrUndefined(this.data.parentCompany) && c.id === this.data.parentCompany), ...this.companies];
    this.data.company = null;
    this.loadPermissions();
  }

  getGroupAuthorities(role) {
    return !isNullOrUndefined(this.requiredAuthorities.roleManager[role.toLowerCase()]) ? this.requiredAuthorities.roleManager[role.toLowerCase()] : [];
  }

  getAuthoritiesForRole(highPrivileges: boolean) {
    if (highPrivileges) {
      return this.requiredAuthorities.highPrivilegeGroups;
    }

    return [];
  }

  get readonly() {
    return this.permissions === 'r';
  }

  get showDocumentClass() {
    const role = this.roles.find(role => role.groupCode.toUpperCase() === 'BASIC_OFFICER');
    return !isNullOrUndefined(role) ? role.selected : false;
  }

  openAlert(id: any, type: string, message: string) {
    this.alertModal.openModalAlert(id, type, message);
  }

  callbackApprovation(type: string) {
    if (type === 'success-gotolist') {
      this.goTolist();
    }
  }

  validationFailed() {
    // console.log('valid',this.documentClasses);
    return this.documentClasses.filter(d => d.selected).length === 0;
  }
}
