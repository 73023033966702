export class Language {
  id: string;
  code: string;
  description: string;

  constructor(
    id: string,
    code: string,
    description: string
  ) {
    this.id = id;
    this.code = code;
    this.description = description;
  }
}
