import {GeneralSetting} from './general-setting.model';
import {User} from './user.model';
import {Company} from './company.model';
import {SelectableModel} from './selectable-model';

export class GeneralSettingValue  extends SelectableModel {
    id: number;
    value: string;
    setting: GeneralSetting;
    settingId: number;
    user: User;
    userId: number;
    society: Company;
    societyId: number;

    constructor(id?: number, value?: string, setting?: GeneralSetting, settingId?: number, user?: User, userId?: number, society?: Company, societyId?: number) {
        super(false);
        this.id = id;
        this.value = value;
        this.setting = setting;
        this.settingId = settingId;
        this.user = user;
        this.userId = userId;
        this.society = society;
        this.societyId = societyId;
    }
}
