<div class="card h-100" [ngClass]="{'card-nopadding': noPadding, 'bg-primary': variant === 'primary'}">
  <div class="card-body">
    <h5 class="card-title" *ngIf="title">{{title}}</h5>
    <div class="card-text" [ngClass]="{'text-white': variant === 'primary'}">
      <ng-container [ngTemplateOutlet]="contentRef" [ngTemplateOutletContext]="{data: data}"></ng-container>
    </div>
  </div>
  <div class="card-footer" *ngIf="link || footerRef">
    <div class="card-actions" *ngIf="link">
      <a [href]="link" class="btn btn-primary">Vedi tutto</a>
    </div>
    <ng-container *ngIf="footerRef" [ngTemplateOutlet]="footerRef" [ngTemplateOutletContext]="footerData"></ng-container>
  </div>
</div>
