import {LocalDate} from '../models/dates/local-date';
// @ts-ignore
import moment, {Moment} from 'moment';

export class DateUtils {
  static readonly DATE_FORMAT: string = 'YYYY-MM-DD';
  static readonly HUMAN_FORMAT: string = 'DD/MM/YYYY';

  static isLocalDate(date: any): date is LocalDate {
    if (typeof date !== 'object') {
      return false;
    }

    return (date as LocalDate).year !== undefined &&
      (date as LocalDate).month !== undefined &&
      (date as LocalDate).day !== undefined;
  }

  static isValidLocalDate(date: LocalDate): boolean {
    return date.year > 1000 && date.month > 0 && date.month < 13 && date.day > 0 && date.day <= 31;
  }

  static localDateToMoment(localDate: LocalDate): Moment {
    if (localDate == null) {
      return null;
    }

    const date = DateUtils.localDateToUtc(localDate);
    return moment(date);
  }

  static localDateToDate(localDate: LocalDate | Date): Date {
    if (localDate == null) {
      return null;
    }
    if (!DateUtils.isLocalDate(localDate)) {
      return localDate;
    }

    const utc = DateUtils.localDateToUtc(localDate);
    return new Date(utc);
  }

  static localDateToUtc(date: LocalDate): number {
    if (date == null) {
      return null;
    }

    return Date.UTC(date.year, date.month - 1, date.day);
  }

  static unixToLocalDate(unix: number): LocalDate {
    if (unix == null) {
      return null;
    }

    const date = new Date(unix);
    return DateUtils.dateToLocalDate(date);
  }

  static dateToLocalDate(date: Date): LocalDate {
    if (date == null) {
      return null;
    }

    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate()
    };
  }

  static stringToLocalDate(string: string): LocalDate {
    if (string == null) {
      return null;
    }

    return DateUtils.dateToLocalDate(moment(string).toDate());
  }

  static formatLocalDate(localDate: LocalDate, format: string): string {
    if (localDate == null) {
      return null;
    }
    return DateUtils.localDateToMoment(localDate).format(format);
  }

  static formatDate(date: Date, format: string): string {
    return moment(date).format(format);
  }

  static formatDateAsDate(date: Date): string {
    return DateUtils.formatDate(date, DateUtils.DATE_FORMAT);
  }

  static formatLocalDateAsDate(localDate: LocalDate): string {
    return DateUtils.formatLocalDate(localDate, DateUtils.DATE_FORMAT);
  }

  static localDateToIsoString(localDate: LocalDate): string {
    return DateUtils.localDateToMoment(localDate).toISOString();
  }

  static formatHumanDate(date: Date): string {
    return DateUtils.formatDate(date, DateUtils.HUMAN_FORMAT);
  }
}
