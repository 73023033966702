<header>
  <div class="row header">
    <div class="col">
      <div class="row">
        <div class="col">
          <div class="rightHeader d-flex flex-row-reverse">
            <div class="p-3">
              <div class="d-inline-block" *ngIf="languages.length>0">
                <ng-select [items]="languages"
                           name="languageSelector"
                           [searchable]="false"
                           (change)="changeLanguage()"
                           bindValue="code"
                           bindLabel="description"
                           class="custom"
                           [clearable]="false"
                           [(ngModel)]="currentLanguage">
                </ng-select>
              </div>
              <div class="d-inline-block pointer" ngbDropdown #myDrop="ngbDropdown">
                <div class="user-baloon"  id="dropdownManual" ngbDropdownAnchor (click)="myDrop.open()">{{userInitials|uppercase}}</div>
                <div ngbDropdownMenu aria-labelledby="dropdownManual">
                  <a ngbDropdownItem routerLink="/account/change-password">{{ 'header.action.change-password' | translate }}</a>
                  <button ngbDropdownItem (click)="logout()">{{ 'generic.action.logout' | translate }}</button>
                </div>
              </div>
            </div>
<!--            <div class="p-3">-->
<!--              <img src="/assets/images/icons/bell.svg" class="notification-image" alt="">-->
<!--            </div>-->
<!--            <div class="p-3" *ngIf="false">-->
<!--              <input type="text" maxlength="255" class="form-control search" value="" placeholder="Ricerca">-->
<!--            </div>-->
          </div>
          <div class="welcomeText">
            {{ 'header.welcome-message' | translate }} <span *ngFor="let user of users">{{user.firstName}} {{user.lastName}}</span>
            (<a (click)="logout()" href="#">{{ 'generic.action.logout' | translate }}</a>)
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
