import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DropdownOption} from '../models/generics/dropdown-option';
import {environment} from '../../environments/environment';
import {Ateco} from "../models/ateco.model";

@Injectable({
  providedIn: 'root'
})
export class AtecoService {

  constructor(private http: HttpClient) {
  }

  getAll() {
    return this.http.get<Ateco[]>(`${environment.apiUrl}/business-according`);
  }
}
