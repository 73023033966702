import {AuthenticationOptions} from '@pwc/security';
import {environment} from '../../environments/environment';

export class AuthenticationServiceConfig implements AuthenticationOptions {
  cookieDomain = environment.cookieDomain;
  cookiePath = environment.cookiePath;
  frontendUrl = '';
  url = environment.apiUrl;
  loginUrl = environment.meUrl;
  secureFlag = environment.secureFlag;
}
