import {Entity} from "./entity";
import {GeneralSettingTypeEnum} from "../../../enums/GeneralSettingTypeEnum";

export class GeneralSettingEntity extends Entity {
    code: string;
    description: string
    userProfiling: boolean;
    societyProfiling: boolean;
    valueType: GeneralSettingTypeEnum;
}
