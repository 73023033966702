<pwc-layout>
  <ng-container app-nav-menu>
    <app-sidebar></app-sidebar>
  </ng-container>

  <ng-container app-header>
    <app-header></app-header>
  </ng-container>

</pwc-layout>
