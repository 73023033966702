import {Inject, Injectable} from '@angular/core';
// noinspection TypeScriptPreferShortImport
import {HttpService} from '../http/http.service';
import {Society} from '../../models';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {UserService} from './user.service';
import {filter} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class SocietiesService extends HttpService<Society, Society> {
  protected endpoint = 'societies';

  private currentSocietySubject: BehaviorSubject<Society> = new BehaviorSubject<Society>(null);
  public currentSociety$: Observable<Society> = this.currentSocietySubject.asObservable();

  constructor(http: HttpClient,
              @Inject('FRAMEWORK_BASEURL') protected baseUrl: string,
              private userService: UserService) {
    super(http);
  }

  getAll() {
    return this.search();
  }

  getUserCompany(): Observable<Society[]> {
    return this.userService.getOnlyChildSocieties();
  }

  /**
   * Get currently selected society.
   * Filters out null values from currentSociety$
   */
  getSelectedSociety(): Observable<Society> {
    return this.currentSociety$.pipe(
      filter((society: Society) => society != null)
    );
  }

  setSociety(society: Society): void {
    this.currentSocietySubject.next(society);
  }

  convertModelToResource(model: Society): Society {
    return model;
  }

  convertResourceToModel(resource: Society): Society {
    return resource;
  }
}
