import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {NavigationMenu} from '../../enums/navigation-menu.enum';
import {NavigationService} from '../../services/navigation.service';
import {AuthenticationService} from '@pwc/security';

@Component({
  selector: 'app-general-settings',
  templateUrl: './general-settings.component.html',
  styleUrls: ['./general-settings.component.scss']
})
export class GeneralSettingsComponent implements OnInit {
    showLoading: boolean;
    permissions: string;

    constructor(private router: Router,
                private authenticationService: AuthenticationService,
                private navigationService: NavigationService) {
    }

    showLoadingHander(show: boolean) {
        this.showLoading = show;
    }

    ngOnInit() {
        this.showLoading = false;
        this.authenticationService.checkAdmin();
        this.permissions = 'rw';
        this.navigationService.setActiveMenu(NavigationMenu.SETTINGS);
    }

    openDetail(id: number, perm: string) {
        this.router.navigateByUrl('/general-settings/' + id + '/' + perm);
    }

    openDetailHandler(obj: any) {
        // this.userName = $event;
        this.openDetail(obj.id, obj.permission);
    }



}
