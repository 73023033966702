import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NavigationMenu} from '../../../enums/navigation-menu.enum';
import {NavigationService} from '../../../services/navigation.service';
import {environment} from '../../../../environments/environment';
import {first} from 'rxjs/operators';
import {Authorities} from '../../../enums/security/authorities.enum';
import {AuthenticationService} from '@pwc/security';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  active: boolean;
  showDesc: boolean;
  activeMenu: NavigationMenu = NavigationMenu.MAIN;
  NavigationMenu = NavigationMenu;
  showAdminMenu: boolean = false;
  menuCommunicoCollaped: boolean = true;

  url2086 = environment.url2086;
  urlDocumentale = environment.urlDocumentale;
  urlEcobonus = environment.urlEcobonus;

  showElectronicInvoice: boolean = false;
  show2086: boolean = false;
  showExpenseReport: boolean = false;
  showGovernmentIncentives: boolean = false;
  showDocumentManagement: boolean = false;
  showEcobonus: boolean = false;

  requiredAuthorities = {
    users: [
      Authorities.ROLE_USER_READ,
      Authorities.ROLE_USER_CREATE,
      Authorities.ROLE_USER_UPDATE,
      Authorities.ROLE_USER_DELETE,
    ],
    company: [
      Authorities.ROLE_COMPANY_READ
    ],
    teams: [
      Authorities.ROLE_TEAM_READ,
      Authorities.ROLE_TEAM_CREATE,
      Authorities.ROLE_TEAM_UPDATE,
      Authorities.ROLE_TEAM_DELETE,
    ],
    generalSettings: [
      Authorities.ROLE_GENERAL_SETTING_VALUE_READ,
      Authorities.ROLE_GENERAL_SETTING_VALUE_CREATE,
      Authorities.ROLE_GENERAL_SETTING_VALUE_UPDATE,
      Authorities.ROLE_GENERAL_SETTING_VALUE_DELETE,
    ],
    ecobonus: [
      Authorities.ROLE_ECOBONUS_ACCESS
    ],
    dms: [
      Authorities.ROLE_DMS_ACCESS
    ]
  };

  configAuthorities = [
    ...this.requiredAuthorities.users,
    ...this.requiredAuthorities.company,
    ...this.requiredAuthorities.teams,
    ...this.requiredAuthorities.generalSettings,
  ];

  constructor(private router: Router,
              private navigationService: NavigationService,
              private authenticationService: AuthenticationService,
              private changeDetector: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.active = false;
    this.showDesc = true;

    this.authenticationService.customerProfilesUser()
      .pipe(first())
      .subscribe(
        (data: any[]) => {
          console.log('REX', data);
          if (data.length === 0) {
            this.showAdminMenu = false;
            this.show2086 = false;
            this.showDocumentManagement = true;
          } else {
            this.show2086 = false;
            this.showDocumentManagement = false;
            // this.showAdminMenu = data.reduce((show: boolean, role: any) => role.groupCode.toLowerCase() === 'super_user' || role.groupCode.toLowerCase() === 'pwc_super_user' , false);
            this.showAdminMenu = data.filter(x => x.groupCode.toLowerCase() === 'super_user' || x.groupCode.toLowerCase() === 'pwc_super_user').length > 0;
            // this.showAdminMenu = data.filter(p => p.code === Authorities.ROLE_GENERAL_SETTING_VALUE_READ || p.code === 'ROLE_GENERAL_SETTING_VALUE_READ').length > 0;
          }

          this.changeDetector.detectChanges();
        },
        error => {
          // console.error(error);
          this.showAdminMenu = false;
          this.show2086 = false;
          this.showDocumentManagement = true;

          this.changeDetector.detectChanges();
        });

    this.navigationService.changeMenu$.subscribe((menu: NavigationMenu) => {
      this.activeMenu = menu;
      this.changeDetector.detectChanges();
    });
  }

  goTo2086() {
    window.location.href = this.url2086;
  }

  get showSettings(): boolean {
    return this.activeMenu === NavigationMenu.SETTINGS;
  }
}
