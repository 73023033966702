import {ApiResource} from '../../models';

export class UserResource extends ApiResource {
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  email?: string;
  token?: string;
  language: string;
  enabled = true;
  userInfoEcobonus: any;
  userInfoDiagnostico: any;
}
