<div class="card">
  <div class="card-body">
    <h5 class="card-title">
      <span translate>generic.filters.label</span>
      <button class="btn btn-primary btn-sm btn-action-icon ml-0 btn-clear-filter" ngbTooltip="Svuota Filtri"
              (click)="resetFilters()" onmousedown="event.preventDefault();" type="button">
        <pwc-icon name="clear"></pwc-icon>
      </button>
    </h5>

    <div class="d-flex flex-row justify-content-between align-items-end">
      <div class="d-flex flex-row flex-wrap">

        <!-- ID filter -->
        <div class="form-group filter-item mr-4">
          <label class="form-input-label no-wrap-label" for="filterIDText" translate>
            generic.field.id
          </label>
          <div class="form-group form-input-group">
            <input class="form-control form-input no-shadow input-with-border" type="text" maxlength="255"
                   name="filterIDText" id="filterIDText" [(ngModel)]="service.searchID"/>
          </div>
        </div>

        <!-- Key filter -->
        <div class="form-group filter-item mr-4">
          <label class="form-input-label no-wrap-label" for="filterKeyText" translate>
            generic.field.key
          </label>
          <div class="form-group form-input-group">
            <input class="form-control form-input no-shadow input-with-border" type="text" maxlength="255"
                   name="filterKeyText" id="filterKeyText"
                   [(ngModel)]="service.searchName"/>
          </div>
        </div>

        <!-- Company filter -->
        <div class="form-group filter-item mr-4">
          <label class="form-input-label no-wrap-label" for="filterCompanySelect" translate>
            generic.field.company
          </label>
          <ng-select [items]="companyList"
                     id="filterCompanySelect"
                     name="filterCompanySelect"
                     [searchable]="true"
                     bindValue="id"
                     bindLabel="name"
                     [(ngModel)]="service.searchCompany">
          </ng-select>
        </div>

        <!-- User filter -->
        <div class="form-group filter-item mr-4">
          <label class="form-input-label no-wrap-label" for="filterUserSelect" translate>
            generic.field.user
          </label>
          <ng-select [items]="userList"
                     id="filterUserSelect"
                     name="filterUserSelect"
                     [searchable]="true"
                     bindValue="id"
                     bindLabel="username"
                     [(ngModel)]="service.searchUsername">
          </ng-select>
        </div>

      </div>
    </div>
  </div>
</div>

<div class="d-flex flex-row">
  <button type="button" class="btn btn-ghost ml-auto align-self-end text-nowrap my-3" [routerLink]="" (click)="export()"
          translate>
    generic.action.export-excel
  </button>
</div>

<div class="card">
  <div class="card-body card-table">
    <table class="table table-striped table-hover">
      <thead>
      <tr>
        <th scope="col" sortable="id" (sort)="onSort($event)" translate>generic.field.id</th>
        <th scope="col" sortable="setting.description" (sort)="onSort($event)" translate>generic.field.key</th>
        <th scope="col" sortable="society.name" (sort)="onSort($event)" translate>generic.field.company</th>
        <th scope="col" sortable="user.username" (sort)="onSort($event)" translate>generic.field.user</th>
        <th scope="col" sortable="value" (sort)="onSort($event)" translate>generic.field.value</th>
        <th scope="col" style="width: 50px"></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let row of list$ | async">
        <td>
          <ngb-highlight [result]="row.id | idToStringPipe"></ngb-highlight>
        </td>
        <td>
          <ngb-highlight [result]="row.setting?.description | uppercase"
                         [term]="service.searchTerm"></ngb-highlight>
        </td>
        <td>
          <ngb-highlight [result]="row.society?.name | uppercase"
                         [term]="service.searchTerm"></ngb-highlight>
        </td>
        <td>
          <ngb-highlight [result]="row.user?.username | uppercase"
                         [term]="service.searchTerm"></ngb-highlight>
        </td>
        <td>
          <ngb-highlight *ngIf="row.setting.valueType === GeneralSettingTypeEnum.TEXT"
                         [result]="row.value | uppercase"
                         [term]="service.searchTerm"></ngb-highlight>
          <span *ngIf="row.setting.valueType === GeneralSettingTypeEnum.PASSWORD">***************</span>
        </td>
        <td>
          <div style="white-space: nowrap;">
            <button type="button" class="btn btn-primary btn-sm btn-action-icon"
                    (click)="onOpenDetail(row.id, 'r')"
                    ngbTooltip="{{ 'generic.action.show-item' | translate }}"
                    *canAccess="authoritiesForAction('read')">
              <img src="/assets/images/icons/visualizza.svg">
            </button>
            <button type="button" class="btn btn-primary btn-sm btn-action-icon"
                    (click)="onOpenDetail(row.id, 'rw')" ngbTooltip="{{ 'generic.action.edit-item' | translate }}"
                    *canAccess="authoritiesForAction('update')">
              <img src="/assets/images/icons/modifica.svg">
            </button>
            <button type="button" class="btn btn-primary btn-sm btn-action-icon"
                    ngbTooltip="{{ 'generic.action.delete.label' | translate }}"
                    (click)="deleteElement(row.id)" *canAccess="authoritiesForAction('delete')">
              <img src="/assets/images/icons/elimina.svg">
            </button>
          </div>
        </td>
      </tr>
      </tbody>
    </table>

    <div class="d-flex justify-content-between p-2 table-paginator">
      <ngb-pagination
        (pageChange)="pageChange()"
        style="float: left"
        [maxSize]="5" [rotate]="true"
        [collectionSize]="total$ | async" [(page)]="service.page" [pageSize]="service.pageSize">
      </ngb-pagination>

      <ng-select [(ngModel)]="service.pageSize"
                 style="width: auto;"
                 name="pageSize"
                 [clearable]="false"
                 class="custom"
                 [searchable]="false">
        <ng-option *ngFor="let elem of [ { value: 10 }, { value: 20 }, { value: 50 } ]"
                   [value]="elem.value">{{ elem.value }} {{ 'generic.action.switch-row-per-page' | translate }}
        </ng-option>
      </ng-select>
    </div>
  </div>
</div>
<div class="d-flex flex-row-reverse my-3 p-2"
     *canAccess="[requiredAuthorities.create]">
  <button type="button" class="btn btn-primary" routerLink="new">{{ 'generic.action.new-setting' | translate }}</button>
</div>
<app-alert #alertModalList [model]="" (onAnyCloseEmitter)="callbackApprovation($event)"></app-alert>
