<footer class="d-flex">
    <div class="align-self-center">©2020 PwC. All rights reserved. PwC refers to the US member firm of the PwC network or one of its subsidiaries or affiliates.</div>
<!--    <div class="d-flex ml-auto">-->
<!--        <div class="ml-3">-->
<!--            <a routerLink="#">Full Legal</a>-->
<!--        </div>-->
<!--        <div class="ml-3">-->
<!--            <a routerLink="#">Privacy Policy</a>-->
<!--        </div>-->
<!--        <div class="ml-3">-->
<!--            <a  routerLink="#">Terms of Use</a>-->
<!--        </div>-->
<!--    </div>-->

</footer>
