import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../../services/user.service';
import {MustMatch} from '../../../helpers/must-match.validator';
import {GenericSelector} from '../../../models/generic-selector.model';
import {TranslateService} from '@ngx-translate/core';
import {mapTranslateMessages, tranlastionKeys} from '../../../helpers/generic-utils';
import {TeamsService} from 'src/app/services/teams.service';
import {Team} from '../../teams/models/team';
import {AuthenticationService, AuthorizationService, CryptoService, PasswordStrengthService} from '@pwc/security';
import {SelectOption} from '@pwc/ui';
import {PasswordStrategyCodeEnum} from 'src/app/enums/password-strategy-code.enum';
import {piInfo, PwcIconsLibrary} from '@pwc/icons';
import {Subscription} from 'rxjs';
import {Authorities} from '../../../enums/security/authorities.enum';
import {switchMap} from 'rxjs/operators';
import {User} from '../../../models/user.model';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnInit, OnDestroy {
  userForm: FormGroup;
  id: number;
  permissions: string;
  data: User;
  mode: string;
  initialized: boolean = false;
  submitted: boolean = false;
  loading: boolean = false;
  langList: GenericSelector[] = [];
  teams: Team[] = [];
  selectedTeams: number[] = [];
  passwordStrategies: SelectOption[] = [];
  PasswordStrategyCodeEnum = PasswordStrategyCodeEnum;

  @ViewChild('alertModal', {static: true}) alertModal: any;

  messages: any;
  subscriptions: Subscription[] = [];

  requiredValidator = [Validators.required];
  emptyValidator = [];

  constructor(private router: Router,
              private route: ActivatedRoute,
              private formBuilder: FormBuilder,
              private userService: UserService,
              private translateService: TranslateService,
              private authenticationService: AuthenticationService,
              private authorizationService: AuthorizationService,
              private teamService: TeamsService,
              private cryptoService: CryptoService,
              private library: PwcIconsLibrary,
              private passwordStrengthService: PasswordStrengthService) {
    this.library.registerIcons([
      piInfo
    ]);

    this.initTranslations();
  }

  initTranslations() {
    if (this.translateService == null) {
      return;
    }

    this.subscriptions.push(
      this.translateService.get(tranlastionKeys).subscribe(translated => {
        this.messages = mapTranslateMessages(translated);
        // console.log('messages', this.messages);
      })
    );
  }

  ngOnInit() {
    // this.loader.show();
    this.loading = true;
    this.authenticationService.checkAdmin();
    this.data = new User();

    this.passwordStrategies = [
      {
        id: PasswordStrategyCodeEnum.MANUAL,
        label: 'generic.field.password-manual-strategy'
      },
      // {
      //   id: PasswordStrategyCodeEnum.INVITATION,
      //   label: 'generic.field.password-invitation-strategy'
      // }
    ];

    this.initTeams();

    if (this.router.url.endsWith('/new')) {
      this.mode = 'new';
      this.permissions = 'rw';
      this.loading = false;
      this.initialized = true;
      // this.loader.hide();
    } else {
      this.mode = 'edit';
      this.subscriptions.push(
        this.route.params.subscribe(params => {
          // console.log('params', params);
          this.id = +params.id; // (+) converts string 'id' to a number
          this.permissions = params.permissions;
          this.teamService.search({}).subscribe(teams => {
            this.teams = teams;

            // load user
            this.userService.getUser(this.id).subscribe(user => {
              this.data = user;
              this.selectedTeams = this.getTeams(user);
              // this.loader.hide();
              this.initialized = true;
              this.loading = false;
              this.initValidation();
            }, (err) => {
              // console.log(err);
              this.openAlert(0, 'error', this.messages.errorLoading);
              this.loading = false;
              // this.loader.hide();
              // this.goTolist();
            });
          });
        })
      );

    }

    this.subscriptions.push(
      this.userService.getCustomerLanguages().subscribe(list => {
        this.langList = list;
        //this.initValidation();
      }, (err) => {
        // console.error(err);
        this.openAlert(0, 'error', this.messages.errorLoading);

      })
    );

    this.initValidation();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  private initTeams(): void {
    this.subscriptions.push(
      this.authorizationService.checkAuthorities(Authorities.ROLE_TEAM_READ)
        .pipe(
          switchMap((authorized: boolean) => {
            if (authorized) {
              return this.teamService.search({});
            } else {
              return this.authenticationService.dataUser()
                .pipe(
                  switchMap((user: User) => this.teamService.getByUserid(user.id, true))
                );
            }
          })
        ).subscribe(data => {
        this.teams = data;
      })
    );
  }

  private initValidation() {
    this.selectedTeams = this.getTeams(this.data);
    this.userForm = this.formBuilder.group({
      username: [this.data.username, Validators.required],
      userType: [this.data.userType, Validators.required],
      lastName: [this.data.lastName, Validators.required],
      firstName: [this.data.firstName, Validators.required],
      companyName: [this.data.companyName, []],
      fiscalCode: [this.data.fiscalCode, []],
      vat: [this.data.vat, []],
      phone: [this.data.phone, []],
      language: [this.data.language, Validators.required],
      email: [this.data.email, [Validators.required, Validators.email]],
      enabled: [this.data.enabled, Validators.required],
      requirePasswordChange: [this.data.requirePasswordChange, Validators.required],
      passwordStrategy: [this.data.passwordStrategy, this.mode === 'new' ? Validators.required : []],
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', Validators.required],
      teams: [this.formBuilder.array(this.selectedTeams)],

    }, {
      validator: MustMatch('password', 'confirmPassword')
    });

    this.subscriptions.push(
      this.userForm.get('userType').valueChanges
        .subscribe(value => {
          if (value == 'COMPANY') {
            this.userForm.get('companyName').setValidators(this.requiredValidator);
          } else {
            this.userForm.get('companyName').setValidators(this.emptyValidator);
          }
        })
    );
  }

  get f() {
    return this.userForm.controls;
  }

  goTolist() {
    this.router.navigateByUrl('users');
  }

  goToUser() {
    // console.log('id', this.id);
    this.router.navigateByUrl(`users/${this.id}/${this.permissions}`);
  }

  readonly() {
    return this.permissions === 'r';
  }

  save() {
    this.submitted = true;
    // console.log('this.userForm.invalid', this.userForm.invalid);
    if (!this.checkFormValid()) {
      return;
    }

    const password = this.f.password.value;
    if (password !== '' && this.data.passwordStrategy === PasswordStrategyCodeEnum.MANUAL) {
      //  Check password strength.
      const strength = this.passwordStrengthService.checkPassword(password);
      if (strength != null && strength.score < 3) {
        this.openAlert(0, 'error', this.translateService.instant('generic.message.validation.weak-password'));
        return;
      }
    }

    // this.loader.show();
    this.loading = true;
    const passw = this.cryptoService.encryptToString(password);
    // this.data = new User(this.data.id, this.f.username.value, passw, this.f.firstName.value, this.f.lastName.value, this.f.fiscalCode.value, this.f.language.value, this.f.email.value, this.data.token, this.f.enabled.value, true, null, this.f.passwordStrategy.value,
    //   this.f.userType.value, this.f.vat.value, this.f.companyName.value, this.f.phone.value, this.data.userInfoEcobonus);

    this.data = {
      ...this.data,
      username: this.f.username.value,
      password: passw,
      firstName: this.f.firstName.value,
      lastName: this.f.lastName.value,
      fiscalCode: this.f.fiscalCode.value,
      language: this.f.language.value,
      email: this.f.email.value,
      enabled: this.f.enabled.value,
      flagValid: true,
      passwordStrategy: this.f.passwordStrategy.value,
      userType: this.f.userType.value,
      vat: this.f.vat.value,
      companyName: this.f.companyName.value,
      phone: this.f.phone.value
    };

    if (passw === '' || this.data.passwordStrategy !== PasswordStrategyCodeEnum.MANUAL) {
      delete this.data.password;
    }
    if (this.data.passwordStrategy === PasswordStrategyCodeEnum.MANUAL) {
      this.data.requirePasswordChange = this.f.requirePasswordChange.value;
    } else {
      this.data.requirePasswordChange = false;
    }
    this.addSelectedTeams();

    // console.log('SEND:', this.data);
    if (this.mode === 'new') {
      // Force MFA
      this.data.mfaEnabled = true;

      this.userService.addUser(this.data).subscribe((res: User) => {
        // console.log('RISP:', res);
        this.id = res.id;
        // this.loader.hide();
        this.openAlert(0, 'success', this.messages.savingSuccess);
        this.loading = false;
        //
      }, (err) => {
        // console.log(err);
        if (err.status === 422 && err.error.type === 'unique_constraint_violated') {
          const msg = `${this.messages.validationFieldDuplicated} ${err.error.details.property}`;
          this.openAlert(0, 'error', msg);
        } else if (err.status === 422 && err.error.type === 'invalid') {
          const msg = `${this.messages.validationFieldInvalid} ${err.error.details.property}`;
          this.openAlert(0, 'error', msg);
        } else {
          this.openAlert(0, 'error', this.messages.errorSaving);
        }
        this.loading = false;
      });
    } else if (this.mode === 'edit') {
      this.userService.updateUser(this.data).subscribe((res: User) => {
        // console.log('RISP:', res);
        this.id = res.id;
        // this.loader.hide();
        this.openAlert(0, 'success', this.messages.savingSuccess);
        this.loading = false;
        //
      }, (err) => {
        console.error(err);
        if (err.status === 422 && err.error.type === 'unique_constraint_violated') {
          const msg = `${this.messages.validationFieldDuplicated} ${err.error.details.property}`;
          this.openAlert(0, 'error', msg);
        } else if (err.status === 422 && err.error.type === 'invalid') {
          const msg = `${this.messages.validationFieldInvalid} ${err.error.details.property}`;
          this.openAlert(0, 'error', msg);
        } else {
          this.openAlert(0, 'error', this.messages.errorSaving);
        }
        this.loading = false;
      });
    }
  }

  private getTeams(user: User): number[] {
    let selectedTeams = [];
    if (user.teamUsers != null) {
      user.teamUsers.forEach(item => {
        let team = this.getTeamById(item.teamId);
        if (team != null) {
          selectedTeams.push(team);
        }
      });
    }
    console.log(selectedTeams);
    return selectedTeams.map((team: Team) => team.id);
  }

  getTeamById(teamId) {
    let filteredTeams = this.teams.filter(item => item.id == teamId);
    if (filteredTeams.length > 0) {
      return filteredTeams[0];
    } else {
      return null;
    }
  }

  addSelectedTeams() {
    this.data.teamUsers = this.selectedTeams.map(teamId => ({teamId}));
  }

  openAlert(id: any, type: string, message: string) {
    this.alertModal.openModalAlert(id, type, message);
  }

  callbackApprovation(type: string) {
    if (type === 'success') {
      if (this.mode === 'new') {
        this.goToUser();
      } else {
        this.goTolist();
      }
    }
  }

  checkFormValid() {
    if (!this.f.username.invalid && !this.f.firstName.invalid && !this.f.lastName.invalid && !this.f.email.invalid && !this.f.language.invalid &&
      !(this.f.userType.invalid) &&
      !((this.f.fiscalCode.value == null || this.f.fiscalCode.value == '')
        && (this.f.vat.value == null || this.f.vat.value == ''))
    ) {
      if (this.mode === 'new') {
        if (this.f.passwordStrategy.value !== PasswordStrategyCodeEnum.INVITATION) {
          return !this.userForm.invalid;
        } else {
          return true;
        }
      }
      if (this.mode === 'edit') {
        if (this.f.passwordStrategy.value === PasswordStrategyCodeEnum.MANUAL) {
          return !this.userForm.invalid;
        } else {
          return true;
        }
      }
    } else {
      return false;
    }
  }

  getTeamNames(): string {
    return this.teams.filter((team: Team) => this.selectedTeams.indexOf(team.id) > -1)
      .map((team: Team) => team.teamName)
      .join(', ');
  }


}
