<div class="page-container">
  <h3 translate>generic.page-title.general-settings</h3>
  <div class="lds-dual-ring" *ngIf="false"></div>

  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/general-settings" translate>menu.system-configuration.submenu.general-settings</a>
      </li>
      <li class="breadcrumb-item" aria-current="page" translate><a routerLink="/general-setting" translate>generic.page-title.general-settings-list</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page"
          *ngIf="mode=='edit'">{{ generalSetting.setting?.description }}</li>
      <li class="breadcrumb-item active" aria-current="page"
          *ngIf="mode=='new'">{{ 'generic.action.new' | translate }}</li>
    </ol>
  </nav>

  <form [formGroup]="registerForm" *ngIf="initialized">

    <div class="card my-4">
      <h5 class="card-header" translate>
        generic.section.registry
      </h5>

      <div class="card-body">
        <!--    Key -->
        <div class="form-group form-input-group form-inline row my-4">
          <label for="editSettingSelect" class="form-input-label col-md-2" translate>
            generic.field.key
          </label>
          <div class="col-md-6">
            <ng-select *ngIf="!readonly()" [items]="generalSettingList"
                       [ngClass]="{ 'is-invalid': f.editSettingSelect.errors!==null }"
                       name="editSettingSelect"
                       formControlName="editSettingSelect"
                       id="editSettingSelect"
                       [searchable]="true"
                       bindValue="id"
                       bindLabel="description"
                       [readonly]="readonly()"
                       class="custom"
                       (change)="onChangeSettingKey()"
                       [(ngModel)]="generalSetting.settingId">
            </ng-select>
            <div *ngIf="f.editSettingSelect.errors!==null"
                 class="invalid-feedback">
              <div *ngIf="f.editSettingSelect.errors.required" translate>
                generic.message.validation.mandatory-error
              </div>
            </div>
            <span *ngIf="readonly()">{{ generalSetting.setting?.description }}</span>
          </div>
        </div>

        <!--        Value -->
        <div class="form-group form-input-group form-inline row my-4">
          <label for="editValueText" class="form-input-label col-md-2" translate>
            generic.field.value
          </label>
          <div class="col-md-6">
            <textarea *ngIf="!readonly()" class="form-control form-input input-with-border"
                   formControlName="editValueText" type="text" maxlength="255" required
                   name="editValueText" id="editValueText" [(ngModel)]="generalSetting.value"
                   [ngClass]="{ 'is-invalid': f.editValueText.errors!==null }">
            </textarea>
            <div *ngIf="f.editValueText.errors!==null"
                 class="invalid-feedback">
              <div *ngIf="f.editValueText.errors.required" translate>
                generic.message.validation.mandatory-error
              </div>
            </div>
            <span *ngIf="readonly()">{{ generalSetting.value }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="card my-4" *ngIf="userProfiling || societyProfiling">
      <h5 class="card-header" translate>
        generic.section.user-profile
      </h5>

      <div class="card-body">

        <div class="form-group form-input-group form-inline row my-4" *ngIf="societyProfiling">
          <label for="editCompanySelect" class="form-input-label col-md-2" translate>
            generic.field.company
          </label>
          <div class="col-md-6">
            <ng-select *ngIf="!readonly() && companyList.length>0" [items]="companyList"
                       [ngClass]="{ 'is-invalid': f.editCompanySelect.errors!==null }"
                       id="editCompanySelect"
                       name="editCompanySelect"
                       formControlName="editCompanySelect"
                       [searchable]="true"
                       bindValue="id"
                       bindLabel="name"
                       [readonly]="readonly()"
                       class="custom"
                       [(ngModel)]="generalSetting.societyId">
            </ng-select>
            <div *ngIf="f.editCompanySelect.errors!==null"
                 class="invalid-feedback">
              <div *ngIf="f.editCompanySelect.errors.required" translate>
                generic.message.validation.mandatory-error
              </div>
            </div>
            <span *ngIf="readonly()">{{ generalSetting.society?.name }}</span>
          </div>
        </div>

        <div class="form-group form-input-group form-inline row my-4" *ngIf="userProfiling">
          <label for="editUserSelect" class="form-input-label col-md-2" translate>
            generic.field.user
          </label>
          <div class="col-md-6">
            <ng-select *ngIf="!readonly()" [items]="userList"
                       [ngClass]="{ 'is-invalid': f.editUserSelect.errors!==null }"
                       id="editUserSelect"
                       name="editUserSelect"
                       formControlName="editUserSelect"
                       [searchable]="true"
                       bindValue="id"
                       bindLabel="username"
                       [readonly]="readonly()"
                       class="custom"
                       [(ngModel)]="generalSetting.userId">
            </ng-select>
            <div *ngIf=" f.editUserSelect.errors!==null"
                 class="invalid-feedback">
              <div *ngIf="f.editUserSelect.errors.required" translate>
                generic.message.validation.mandatory-error
              </div>
            </div>
            <span *ngIf="readonly()">{{ generalSetting.society?.name }}</span>
          </div>
        </div>

      </div>
    </div>

    <section class="my-3">
      <div class="row">
        <div class="col">
          <div class="d-flex flex-row p-1">
            <button class="btn btn-default mr-auto"
                    (click)="goTolist()">{{ 'generic.action.back' | translate }}</button>
            <button class="btn btn-primary" *ngIf="!readonly()"
                    (click)="save()">{{ 'generic.action.save' | translate }}</button>
          </div>
        </div>
      </div>
    </section>
  </form>
</div>

<app-alert #alertModal [model]="" (onAnyCloseEmitter)="callbackApprovation($event)"></app-alert>
