import {Injectable, PipeTransform} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {User} from '../models/user.model';
import {environment} from '../../environments/environment';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {SortDirection} from '../directives/sortable.directive';
import {first} from 'rxjs/internal/operators/first';
import {tap} from 'rxjs/internal/operators/tap';
import {debounceTime, delay, map} from 'rxjs/operators';
import {switchMap} from 'rxjs/internal/operators/switchMap';
import {of} from 'rxjs/internal/observable/of';
import {SearchResult} from '../models/generics/search-result';
import {GenericSelector} from '../models/generic-selector.model';
import {Company} from '../models/company.model';
import {ExcelExportService} from './excel-export.service';
import {isNullOrUndefined} from 'util';
import {SocietyEntity} from '../models/dto/entities/society-entity';
import {GeneralSettingValue} from '../models/general-setting-value.model';
import {GeneralSettingValueEntity} from '../models/dto/entities/general-setting-value-entity';
import {transformEntityToGeneralSettingValue} from '../helpers/transformers/general-setting.transformer';

interface State {
  page: number;
  pageSize: number;
  searchTerm: string;
  searchID: string;
  searchUsername: string;
  searchFirstName: string;
  searchLastName: string;
  searchEnabled?: boolean;
  sortColumn: string;
  sortDirection: SortDirection;
}

function compare(v1, v2) {
  return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
}

function sort(list: User[], column: string, direction: string): User[] {
  if (direction === '') {
    return list;
  } else {
    return [...list].sort((a, b) => {
      const res = compare(a[column], b[column]);
      return direction === 'asc' ? res : -res;
    });
  }
}

function matches(user: User, term: string) {
  return user.username.toLowerCase().includes(term)
    || user.email.toLowerCase().includes(term)
    || user.firstName.toLowerCase().includes(term)
    || user.lastName.toLowerCase().includes(term);
}

@Injectable({providedIn: 'root'})
export class UserService {
  private loading = new BehaviorSubject<boolean>(true);
  private search$ = new Subject<void>();
  private usersList = new BehaviorSubject<User[]>([]);
  private total = new BehaviorSubject<number>(0);
  private filteredList = new BehaviorSubject<User[]>([]);
  subscriptions: any[] = [];
  users: User[];

  private state: State = {
    page: 1,
    pageSize: 10,
    searchTerm: '',
    searchUsername: '',
    searchFirstName: '',
    searchLastName: '',
    searchID: '',
    searchEnabled: null,
    sortColumn: '',
    sortDirection: ''
  };

  constructor(private http: HttpClient, private excelService: ExcelExportService) {
  }

  unsubscribeAll() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  loadService(isAdmin: boolean = false) {
    this.users = [];
    this.unsubscribeAll();
    let getUsers = null;
    if (isAdmin) {
      getUsers = this.getAll();
    } else {
      getUsers = this.getUsersProfiled();
    }
    getUsers.pipe(first()).subscribe(list => {
      if (!isAdmin) {
        list = list.filter(u => u.id !== 41);
      }
      this.users = list
        .map(i => {
          const user = new User(i.id, i.username, i.password, i.firstName, i.lastName, i.fiscalCode, i.language, i.email, i.token, i.enabled, i.flagValid);
          user.userInfoEcobonus = i.userInfoEcobonus;
          return user;
        });
      console.log('list', this.users);
      const sub = this.search$.pipe(
        tap(() => this.loading.next(true)),
        debounceTime(200),
        switchMap(() => this.search()),
        delay(200),
        tap(() => this.loading.next(false))
      ).subscribe((result: SearchResult<User>) => {
        this.usersList.next(result.items);
        this.filteredList.next(result.filteredList);
        this.total.next(result.total);
      });
      this.subscriptions.push(sub);
      this.search$.next();
    });
  }

  getAll() {
    return this.http.get<User[]>(`${environment.apiUrl}/users`);
  }

  getUsersProfiled() {
    return this.http.get<User[]>(`${environment.apiUrl}/users/me/users-list`);
  }

  getUserSettings(groupId?: any, includeMembership: boolean = false): Observable<GeneralSettingValue[]> {
    console.log('group', groupId);
    let filters = `includeMemberships=${includeMembership}`;
    filters += !isNullOrUndefined(groupId) ? `&groupId=${groupId}` : '';
    return this.http.get<GeneralSettingValueEntity[]>(`${environment.apiUrl}/users/me/general-settings?${filters}`)
      .pipe(map((entities: GeneralSettingValueEntity[]): GeneralSettingValue[] => {
        return entities.map(transformEntityToGeneralSettingValue);
      }));
  }

  getUser(id: any) {
    return this.http.get<User>(`${environment.apiUrl}/users/${id}`);
  }

  getSocieties(groupId?: any, includeMemberships: boolean = false) {
    console.log('group', groupId);
    let filters = `includeMemberships=${includeMemberships}`;

    if (groupId != null) {
      filters += `&groupId=${groupId}`;
    }

    return this.http.get<Company[]>(`${environment.apiUrl}/users/me/societies?${filters}`);
  }

  exportUsers() {
    return this.http.get<any>(`${environment.apiUrl}/users/export`, {
      responseType: 'blob' as 'json'
    });
  }

  addUser(user: User) {
    return this.http.post(`${environment.apiUrl}/users`, user);
  }

  updateUser(user: User) {
    return this.http.put(`${environment.apiUrl}/users`, user);
  }

  changePassword(user: User) {
    return this.http.put(`${environment.apiUrl}/users/change-password`, user);
  }

  deleteUser(id: number) {
    return this.http.delete<User>(`${environment.apiUrl}/users/${id}`);
  }

  deleteAllUsers(list: any[]) {
    const params = list.map(id => `ids=${id}`).join('&');
    return this.http.delete(`${environment.apiUrl}/users?${params}`);
  }

  getCustomerLanguages() {
    return this.http.get<any[]>(`${environment.apiUrl}/languages`);
  }

  private search(): Observable<SearchResult<User>> {
    const {
      page,
      pageSize,
      searchTerm,
      searchUsername,
      searchFirstName,
      searchLastName,
      searchID,
      searchEnabled,
      sortColumn,
      sortDirection
    } = this.state;

    // 1. sort
    let list = sort(this.users, sortColumn, sortDirection);

    // 2. filter
    list = list.filter(user => matches(user, searchTerm));

    if (searchUsername !== '') {
      list = list.filter(user => user.username.toLowerCase().includes(searchUsername.toLowerCase()));
    }

    if (searchID !== '') {
      // list = list.filter(user => user.id === parseInt(searchID, 10)); // search match by ID
      list = list.filter(user => user.id.toString().toLowerCase().includes(searchID));
    }

    if (searchFirstName !== '') {
      list = list.filter(user => user.firstName.toLowerCase().includes(searchFirstName.toLowerCase()));
    }

    if (searchLastName !== '') {
      list = list.filter(user => user.lastName.toLowerCase().includes(searchLastName.toLowerCase()));
    }

    if (typeof searchEnabled !== 'undefined' && searchEnabled !== null) {
      list = list.filter(user => user.enabled === searchEnabled);
    }

    const total = list.length;
    const filteredList = JSON.parse(JSON.stringify(list));
    // console.log('filteredList', filteredList);

    // 3. paginate
    list = list.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    return of({items: list, filteredList, total});
  }

  get usersList$() {
    return this.usersList.asObservable();
  }

  get filteredList$() {
    return this.filteredList.asObservable();
  }

  get total$() {
    return this.total.asObservable();
  }

  get loading$() {
    return this.loading.asObservable();
  }

  get sortColumn() {
    return this.state.sortColumn;
  }

  set sortColumn(sortColumn: string) {
    this.setState({sortColumn});
  }

  get sortDirection() {
    return this.state.sortDirection;
  }

  set sortDirection(sortDirection: SortDirection) {
    this.setState({sortDirection});
  }

  get searchID() {
    return this.state.searchID;
  }

  set searchID(searchID: string) {
    this.setState({searchID});
  }

  get searchUsername() {
    return this.state.searchUsername;
  }

  set searchUsername(searchUsername: string) {
    this.setState({searchUsername});
  }

  get searchFirstName() {
    return this.state.searchFirstName;
  }

  set searchFirstName(searchFirstName: string) {
    this.setState({searchFirstName});
  }

  get searchLastName() {
    return this.state.searchLastName;
  }

  set searchLastName(searchLastName: string) {
    this.setState({searchLastName});
  }

  get searchEnabled() {
    return this.state.searchEnabled;
  }

  set searchEnabled(searchEnabled: boolean) {
    this.setState({searchEnabled});
  }

  get searchTerm() {
    return this.state.searchTerm;
  }

  set searchTerm(searchTerm: string) {
    this.setState({searchTerm});
  }

  get page() {
    return this.state.page;
  }

  set page(page: number) {
    this.setState({page});
  }

  get pageSize() {
    return this.state.pageSize;
  }

  set pageSize(pageSize: number) {
    this.setState({pageSize});
  }

  private setState(patch: Partial<State>) {
    Object.assign(this.state, patch);
    this.search$.next();
  }

  public exportAsExcel(exportedList: any[]) {
    this.excelService.exportAsExcelFile(exportedList, 'Data');
  }
}
