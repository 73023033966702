import { User, BaseModel } from 'projects/pwc/common/src/models';
import { SocietyEntity } from 'src/app/models/dto/entities/society-entity';

export class Team extends BaseModel {

  selected?: boolean
  societyId?: number;
  teamName?: string;
  userId?: number;
  parentId?: number;

  society?: SocietyEntity;
  parent?: Team;
  children?: Team[];

  constructor() {
    super();
    this.society = new SocietyEntity();
    this.selected = false;
  }
}
