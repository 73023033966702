import {Injectable, PipeTransform} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';

import {DecimalPipe} from '@angular/common';
import {debounceTime, delay, switchMap, tap, first, map} from 'rxjs/operators';
import {SortDirection} from '../directives/sortable.directive';
import {environment} from 'src/environments/environment';
import {matches, SearchResult, sort, State} from '../helpers/list-helper';
import {ListServiceHelper} from '../helpers/list-service-helper.service';
import {ExcelExportService} from './excel-export.service';
import {GeneralSettingValue} from '../models/general-setting-value.model';
import {GeneralSettingValueEntity} from '../models/dto/entities/general-setting-value-entity';
import {transformEntityToGeneralSettingValue} from '../helpers/transformers/general-setting.transformer';
import {isNullOrUndefined} from "util";
import {UserService} from "./user.service";

@Injectable({providedIn: 'root'})
export class GeneralSettingValueListService extends ListServiceHelper<GeneralSettingValue> {

    constructor(protected http: HttpClient,
                protected pipe: DecimalPipe,
                protected userService: UserService,
                protected excelExportService: ExcelExportService) {
        super(http, pipe, excelExportService);
    }

    loadService(isAdmin) {
        this.list = [];
        this.unsubscribeAll();
        let getGenSettings = null;
        if (isAdmin) {
            getGenSettings = this.getAll().pipe(first());
        } else {
            getGenSettings = this.userService.getUserSettings(2, true).pipe(first());
                // this.getUserSettings(false).pipe(first());
        }
        getGenSettings.subscribe(list => this.updateList(list));
    }

    getAll(): Observable<GeneralSettingValue[]> {
        return this.http.get<GeneralSettingValueEntity[]>(`${environment.apiUrl}/general-settings`)
            .pipe(map((entities: GeneralSettingValueEntity[]): GeneralSettingValue[] => {
                return entities.map(transformEntityToGeneralSettingValue);
            }));
    }
    getUserSettings(adminProfiling?: boolean): Observable<GeneralSettingValue[]> {
        const params = !isNullOrUndefined(adminProfiling) ? `?setting.adminProfiling=1` : '';
        return this.http.get<GeneralSettingValueEntity[]>(`${environment.apiUrl}/general-settings${params}`)
            .pipe(map((entities: GeneralSettingValueEntity[]): GeneralSettingValue[] => {
                return entities.map(transformEntityToGeneralSettingValue);
            }));
    }


    deleteElement(id: number) {
        return this.http.delete(`${environment.apiUrl}/general-settings/${id}`);
    }

    deleteMassiveElements(list: any[]) {
        const params = list.map(id => `ids=${id}`).join('&');
        return this.http.delete(`${environment.apiUrl}/general-settings?${params}`);
    }
}
