<widget title="Attività recenti" link="#" [contentRef]="widgetContent"></widget>

<ng-template #widgetContent>
  <section class="timeline">
    <div class="timeline-item-wrapper">
      <div class="timeline-item">
        <p class="timeline-item-date">25 GEN</p>
        <p class="timeline-item-content">Scenario creato correttamente</p>
      </div>
    </div>

    <div class="timeline-item-wrapper">
      <div class="timeline-item">
        <p class="timeline-item-date">27 GEN</p>
        <p class="timeline-item-content">Analisi validata</p>
      </div>
    </div>

    <div class="timeline-item-wrapper">
      <div class="timeline-item">
        <p class="timeline-item-date">2 FEB</p>
        <p class="timeline-item-content">Fatturazione elettronica</p>
      </div>
    </div>
  </section>
</ng-template>
