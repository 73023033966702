export enum LocalTranslateEnum {
    errorGeneric = 'generic.message.error-generic',
    errorSaving = 'generic.message.error-saving',
    errorLoading = 'generic.message.error-loading',
    deleteConfirm = 'generic.message.delete-confirm',
    deleteSelectedConfirm = 'generic.message.delete-selected-confirm',
    deleteSuccess = 'generic.message.delete-success',
    savingSuccess = 'generic.message.saving-success',
    validationFieldDuplicated = 'generic.message.validation.duplicated-field',
    validationFieldInvalid = 'generic.message.validation.invalid-field',
    approveSuccess = 'generic.message.approve-success',
    rejectNoteInfoSet = 'generic.message.reject-note-info-set',
    approveSelectedConfirm = 'generic.message.approve-selected-confirm',
    cloneSelectdConfirm = 'generic.message.clone-selected-confirm',
    cloneSuccess = 'generic.message.clone-success',
    scenarioStartSuccess = 'generic.message.scenario-start-success',
    scenarioErrorInfoSetNotApproved = 'generic.message.scenario-error-info-set-not-approved',
    scernarioAlertImfoSetMissing = 'generic.message.scenario-alert-info-set-missing',
    reportCreatedSuccess = 'generic.message.report-created-success',
    rejectSuccess = 'generic.message.reject-success',
    remedationActionNote = 'generic.field.remedation-action',
    usersOwnerActivity = 'generic.text.users-owner-activity',
    rejectInfoSetSuccess = 'generic.message.reject-info-set-success',
    scenarioIndicesListTitle = 'generic.text.scenario-indices-list-title',
    yesAnswer = 'generic.text.yes-answer',
    noAnswer = 'generic.text.no-answer',
    ruleSetValue = 'generic.field.rule-editor.set-value',
    ruleIf = 'generic.field.rule-editor.if',
    ruleElse = 'generic.field.rule-editor.else',
    ruleElseIf = 'generic.field.rule-editor.else-if',
    ruleAnd = 'generic.field.rule-editor.and',
    ruleOr = 'generic.field.rule-editor.or',
    ruleValue = 'generic.field.rule-editor.value',
    ruleResult = 'generic.field.result',
    ruleTrafficLightRed = 'generic.field.rule-editor.traffic-light.red',
    ruleTrafficLightYellow = 'generic.field.rule-editor.traffic-light.yellow',
    ruleTrafficLightGreen = 'generic.field.rule-editor.traffic-light.green',
    ruleTrafficLightGrey = 'generic.field.rule-editor.traffic-light.grey',
    ruleResultFormatPerc = 'generic.field.result-format.perc',
    ruleResultFormatNumber = 'generic.field.result-format.number',
    ruleResultFormatNumberCurrency = 'generic.field.result-format.currency',
    ruleResultFormatAnswer = 'generic.field.result-format.answer',
    legendMonitoringDone = 'generic.legend.monitoring.done',
    legendMonitoringExpiring = 'generic.legend.monitoring.expiring',
    legendMonitoringOverdue = 'generic.legend.monitoring.overdue',
    legendMonitoringTodo = 'generic.legend.monitoring.to-do',
    fieldAnnual = 'generic.field.annual',
    fieldSemiannual = 'generic.field.semiannual',
    fieldQuarterly = 'generic.field.quarterly',
    fieldMonthly = 'generic.field.monthly',
    fieldSemester = 'generic.field.semester',
    fieldQuarter = 'generic.field.quarter',
    fieldJanuary = 'generic.field.january',
    fieldFebruary = 'generic.field.february',
    fieldMarch = 'generic.field.march',
    fieldApril = 'generic.field.april',
    fieldMay = 'generic.field.may',
    fieldJune = 'generic.field.june',
    fieldJuly = 'generic.field.july',
    fieldAugust = 'generic.field.august',
    fieldSeptember = 'generic.field.september',
    fieldOctober = 'generic.field.october',
    fieldNovember = 'generic.field.november',
    fieldDecember = 'generic.field.december',
    fieldPeriod = 'generic.field.period',
}
