import {SelectableModel} from './selectable-model';
import {GeneralSettingTypeEnum} from "../enums/GeneralSettingTypeEnum";

export class GeneralSetting extends SelectableModel {
    id: number;
    code: string;
    description: string
    userProfiling: boolean;
    societyProfiling: boolean;
    adminProfiling: boolean;
    typeValue: GeneralSettingTypeEnum;

    constructor(id?: number, code?: string, description?: string, userProfiling?: boolean, societyProfiling?: boolean, typeValue?: GeneralSettingTypeEnum, adminProfiling?: boolean) {
        super(false);
        this.id = id;
        this.code = code;
        this.description = description;
        this.userProfiling = userProfiling;
        this.societyProfiling = societyProfiling;
        this.adminProfiling = adminProfiling;
        this.typeValue = typeValue;
    }
}
