import {Inject, Injectable} from '@angular/core';
import {UserResource} from '../../resources';
import {HttpService} from '../http/http.service';
import {User, Society, GroupEntity} from '../../models';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService extends HttpService<UserResource, User> {
  protected endpoint = 'users';

  constructor(http: HttpClient,
              @Inject('FRAMEWORK_BASEURL') protected baseUrl: string) {
    super(http);
  }

  getSocieties(): Observable<Society[]> {
    return this.get<Society[]>('me/societies');
  }

  getOnlyChildSocieties(): Observable<Society[]> {
    const filters = `includeMemberships=false`;

    return this.get<Society[]>(`me/societies?${filters}`);
  }

  getSocietiesForGroupCodes(groupCodes: string[]): Observable<Society[]> {
    const groupCodesParam = groupCodes.join(',');

    return this.get<Society[]>(`me/societies/for-groups?groupCodes=${groupCodesParam}`);
  }

  getRolesBySociety(societyId: number): Observable<GroupEntity[]> {
    return this.get<GroupEntity[]>(`me/roles/${societyId}?type=PRIMARY`)
      .pipe(
        map((groups: GroupEntity[]) => groups.filter((group: GroupEntity) => group.selected))
      );
  }

  changePassword(user: User) {
    return this.put(`change-password`, user);
  }

  convertModelToResource(model: User): UserResource {
    return Object.assign<UserResource, User>(new UserResource(), model);
  }

  convertResourceToModel(resource: UserResource): User {
    return Object.assign<User, UserResource>(new User(), resource);
  }
}
