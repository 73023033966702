export enum TraceabilityProcessEnum {
    USER_ACCESS = 'USER_ACCESS',
    ANAG_SOCIETY = 'ANAG_SOCIETY',
    ANAG_USER = 'ANAG_USER',
    INDEX_INFOSET = 'INDEX_INFOSET',
    RULE = 'RULE',
    PLANNING_ACTIVITY = 'PLANNING_ACTIVITY',
    INFO_SET_INSTANCE = 'INFO_SET_INSTANCE',
    SCENARIO = 'SCENARIO',
    REPORTING = 'REPORTING',
    OTHER = 'OTHER'
}
