import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

import {map} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {GeneralSettingValue} from '../models/general-setting-value.model';
import {GeneralSettingValueEntity} from '../models/dto/entities/general-setting-value-entity';
import {
    transformEntityToGeneralSetting,
    transformEntityToGeneralSettingValue,
    transformGeneralSettingValueToEntity
} from '../helpers/transformers/general-setting.transformer';
import {GeneralSetting} from '../models/general-setting.model';
import {GeneralSettingEntity} from '../models/dto/entities/general-setting-entity';
import {isNullOrUndefined} from "util";

@Injectable({providedIn: 'root'})
export class GeneralSettingValueService {
    constructor(private http: HttpClient) {
    }

    getAll() {
        return this.http.get<GeneralSettingValue[]>(`${environment.apiUrl}/general-settings`);
    }

    getDetail(id: any): Observable<GeneralSettingValue> {
        return this.http.get<GeneralSettingValueEntity>(`${environment.apiUrl}/general-settings/${id}`)
            .pipe(map((entity: GeneralSettingValueEntity): GeneralSettingValue => {
                return transformEntityToGeneralSettingValue(entity);
            }));
    }

    add(model: GeneralSettingValue) {
        const entity = transformGeneralSettingValueToEntity(model)
        delete model.setting;
        delete model.society;
        delete model.user;
        return this.http.post(`${environment.apiUrl}/general-settings`, entity);
    }

    update(model: GeneralSettingValue) {
        delete model.setting;
        delete model.society;
        delete model.user;
        const entity = transformGeneralSettingValueToEntity(model)
        // console.log('update model', entity);
        return this.http.put(`${environment.apiUrl}/general-settings`, entity);
    }

    getGeneralSettingKey(adminProfiling?: boolean): Observable<GeneralSetting[]> {
        const params = ''; // !isNullOrUndefined(adminProfiling) ? `?adminProfiling=${adminProfiling.toLocaleString().toUpperCase()}` : '';
        return this.http.get<GeneralSettingEntity[]>(`${environment.apiUrl}/general-settings-reg${params}`)
            .pipe(map((entities: GeneralSettingEntity[]): GeneralSetting[] => {
                return entities.map(transformEntityToGeneralSetting);
            }));
    }
}
