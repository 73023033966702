// noinspection TypeScriptPreferShortImport
import {SelectableModel} from '../base/selectable-model';

export class Society extends SelectableModel {
  id: number;
  name: string;

  constructor(id: number, name: string) {
    super();
    this.id = id;
    this.name = name;
  }
}
