import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-widget-publications',
  templateUrl: './widget-publications.component.html',
  styleUrls: ['./widget-publications.component.scss']
})
export class WidgetPublicationsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
