<widget title="Attività future" link="#" [contentRef]="widgetContent"></widget>

<ng-template #widgetContent>
  <section class="timeline">
    <div class="timeline-item-wrapper">
      <div class="timeline-item">
        <p class="timeline-item-date">20 APR</p>
        <p class="timeline-item-content">Caricamento del bilancio</p>
      </div>
    </div>

    <div class="timeline-item-wrapper">
      <div class="timeline-item">
        <p class="timeline-item-date">27 APR</p>
        <p class="timeline-item-content">Estrazione report</p>
      </div>
    </div>

    <div class="timeline-item-wrapper">
      <div class="timeline-item">
        <p class="timeline-item-date">2 MAG</p>
        <p class="timeline-item-content">Configurazione indice</p>
      </div>
    </div>
  </section>
</ng-template>
