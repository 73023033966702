import {AfterViewInit, Component, HostListener, Injector, OnInit, ViewChild} from '@angular/core';
import {Data, Router, RouterEvent, RoutesRecognized} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {I18nService} from './services/i18n.service';
import {Language} from './models/language.model';
import {filter, map} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {AuthenticationCookie, AuthenticationService, AuthorizationService} from '@pwc/security';

export let AppInjector: Injector;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  languages: Language[] = [];
  currentLanguage: string;

  title = 'communico';
  currentUser: AuthenticationCookie;

  isAuthorized = false;

  private routeData: Subject<Data> = new Subject();
  private routeData$: Observable<Data> = this.routeData.asObservable();

  @ViewChild('sideBar') sideBar: any;
  @ViewChild('header') header: any;

  constructor(private router: Router,
              private translate: TranslateService,
              private authenticationService: AuthenticationService,
              private authorizationService: AuthorizationService,
              private i18n: I18nService,
              injector: Injector) {
    AppInjector = injector;
    translate.setDefaultLang('it');
    this.authenticationService.cookie.subscribe(x => {
      if (x) {
        this.currentUser = x;
      } else {
        this.currentUser = null;
      }
    });
  }

  ngOnInit(): void {
    this.i18n.lang.subscribe((lang: string) => {
      this.currentLanguage = lang;
    });
    this.languages = this.i18n.languages;
    this.authorizeForRoute();
  }

  ngAfterViewInit(): void {
    this.resize(window.innerWidth);
  }

  private authorizeForRoute(): void {
    // We are outside the router-outlet, so we should hook into router events and find out when the route gets recognized.
    this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof RoutesRecognized) {
        this.routeData.next(event.state.root.firstChild.data);
      }
    });

    this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof RoutesRecognized),
      map((event: RoutesRecognized): Data => event.state.root.firstChild.data)
    ).subscribe((data: Data) => {
      this.isAuthorized = false;
      if (typeof data.authorities === 'undefined' || data.authorities.length === 0) {
        // console.log('Route authorization: no authorities required for the current route - authorized: YES');
        this.isAuthorized = true;
        return;
      }

      // this.authorizationService.isLoaded.subscribe((loaded: boolean) => {
      //   if (!loaded) {
      //     // console.log('Route authorization: authorizations not loaded yet - authorized: SKIP');
      //     return;
      //   }

      // console.log('Route authorization: required authorities for current route', data.authorities);
      this.authorizationService.checkAuthorities(data.authorities)
        .subscribe((authorized: boolean) => {
          // console.log('Route authorization: authorization check outcome', authorized);
          this.isAuthorized = authorized;

          if (!authorized) {
            if (typeof data.next === 'undefined') {
              // console.error('Route authorization: configuration error - next route should be defined for authorized routes');
              this.router.navigate(['/']);
              return;
            }

            // console.log('Route authorization: redirect to next route - authorized: NO - next route', data.next);
            this.router.navigate([data.next]);
          }
        });
    });
    // });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    const w = event.target.innerWidth;
    this.resize(w);
  }

  resize(width: number) {
    const _component = this;
    if (!this.currentUser || typeof this.sideBar === 'undefined') {
      return;
    }
    if (width < 1200) {
      if (!this.sideBar.active) {
        this.sideBar.active = true;
        this.sideBar.showDesc = false;
      }
    } else {
      if (this.sideBar.active) {
        this.sideBar.active = false;
        setTimeout(() => {
          _component.sideBar.showDesc = true;
        }, 500);
      }
    }
  }

  logout() {
    this.authenticationService.logout();
    // this.router.navigate(['/login']);
  }

  useLanguage(language: string) {
    this.translate.use(language.toLowerCase());
  }
}
