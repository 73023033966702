import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {NavigationMenu} from '../enums/navigation-menu.enum';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  private menu = new Subject<NavigationMenu>();
  public changeMenu$ = this.menu.asObservable();

  constructor() {
    this.setActiveMenu(NavigationMenu.MAIN);
  }

  public setActiveMenu(menu: NavigationMenu) {
    this.menu.next(menu);
  }
}
