import {Injectable} from '@angular/core';
import {HttpService} from './http.service';
import {Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {ApiResource, BaseModel, PageableResponse} from '../../models';
import {HttpUtils} from '../../utils/http-utils';

@Injectable({
  providedIn: 'root'
})
export abstract class PageableService<R extends ApiResource, M extends BaseModel> extends HttpService<R, M> {
  paginationSubject: Subject<PageableResponse<R[]>> = new Subject<PageableResponse<R[]>>();
  pagination$: Observable<PageableResponse<R[]>> = this.paginationSubject.asObservable();

  public search(search?: Partial<R>): Observable<M[]> {
    const params = HttpUtils.toHttpParams(search);

    return this.get<PageableResponse<R[]>>('', {params}).pipe(
      map((response: PageableResponse<R[]>) => this.extractPaginationInfo(response)),
      map((list: R[]): M[] => this.convertListToModel(list))
    );
  }

  public extractPaginationInfo(response: PageableResponse<R[]>): R[] {
    this.paginationSubject.next({
      content: ([] as R[]),
      pageable: response.pageable,
      totalElements: response.totalElements,
      size: response.size,
      number: response.number,
      numberOfElements: response.numberOfElements
    });

    return response.content || [];
  }
}
