<div class="card my-4">
  <div class="card-body">

    <div class="form-group form-input-group form-inline row my-4">
      <label for="parentCompanySelect" class="form-input-label col-md-2" translate>
        generic.field.parent-company
      </label>
      <div class="col-md-6">
        <ng-select [items]="parentCompanies"
                   id="parentCompanySelect"
                   name="parentCompanySelect"
                   [searchable]="true"
                   bindValue="id"
                   bindLabel="name"
                   class="custom"
                   (change)="filterSocietis()"
                   [clearable]="isAdmin"
                   [(ngModel)]="data.parentCompany">
        </ng-select>
      </div>
    </div>

    <div class="form-group form-input-group form-inline row my-4">
      <label for="editCompanySelect" class="form-input-label col-md-2" translate>
        generic.field.company
      </label>
      <div class="col-md-6">
        <ng-select [items]="companies"
                   id="editCompanySelect"
                   name="editCompanySelect"
                   [searchable]="true"
                   bindValue="id"
                   bindLabel="name"
                   class="custom"
                   (change)="loadPermissions()"
                   [clearable]="true"
                   [(ngModel)]="data.company">
        </ng-select>
      </div>
    </div>
  </div>
</div>

<section>
  <div class="row" *ngIf="data.company!==0 && roles.length>0">
    <div class="col-md-6">
      <div class="card index-info-card card-nopadding">
        <div class="card-body">
          <div class="card-text">
            <table class="table table-striped">
              <thead>
              <tr>
                <th translate>generic.field.role</th>
                <th translate>generic.field.enabled</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let role of roles">
                <ng-container *canAccess="getAuthoritiesForRole(role.highPrivileges)">
                  <td style="vertical-align: middle;">
                    <label style="margin: 0;" [for]="'role-' + role.id">{{role.groupName | uppercase}}</label>
                  </td>
                  <td>
                    <div class="custom-control custom-checkbox info-elem">
                      <input type="checkbox"
                             [disabled]="readonly"
                             [attr.id]="'role-' + role.id" [attr.name]="'role-'+role.id"
                             [ngModelOptions]="{standalone: true}"
                             [(ngModel)]="role.selected"
                             (change)="triggerChangeDetection(role.groupCode, role.selected)"
                             class="custom-control-input">
                      <label class="custom-control-label" [for]="'role-' + role.id">&nbsp;</label>
                    </div>
                  </td>
                </ng-container>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="card index-info-card card-nopadding" *ngIf="showDocumentClass">
        <div class="card-body">
          <div class="card-text">
            <table class="table table-striped">
              <thead>
              <tr>
                <th>
                  {{ 'generic.field.document-class' | translate }}
                  <span *ngIf="!readonly && submitted && validationFailed()" class="invalid-feedback-standalone">
                        <span translate>generic.message.validation.document-class-mandatory</span>
                    </span>
                </th>
                <th translate>generic.field.enabled</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let docClass of documentClasses">
                <td style="vertical-align: middle;">
                  <label style="margin: 0;" [for]="'doc-' + docClass.id">{{docClass.groupName | uppercase}}</label>
                </td>
                <td>
                  <div class="custom-control custom-checkbox info-elem">
                    <input
                      [disabled]="readonly"
                      [attr.id]="'doc-' + docClass.id" [attr.name]="'doc-' + docClass.id"
                      [ngModelOptions]="{standalone: true}" [(ngModel)]="docClass.selected"
                      type="checkbox" class="custom-control-input">
                    <label class="custom-control-label" [for]="'doc-' + docClass.id">&nbsp;</label>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex flex-row pt-4 pb-4">
    <button class="btn btn-default mr-auto" (click)="goTolist()"
            [disabled]="loading">{{ 'generic.action.back' | translate }}</button>
    <button class="btn btn-primary mr-2" *ngIf="!readonly && data.company!==0 && roles.length>0"
            (click)="save(false)" [disabled]="loading">{{ 'generic.action.save' | translate }}</button>
    <button class="btn btn-primary" *ngIf="!readonly && data.company!==0 && roles.length>0" (click)="save()"
            [disabled]="loading">{{ 'generic.action.save-close' | translate }}</button>
  </div>
</section>
<app-alert #alertModalPerm [model]="" (onAnyCloseEmitter)="callbackApprovation($event)"></app-alert>
