import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss']
})
export class WidgetComponent implements OnInit {
  @Input() title: string;
  @Input() link: string;
  @Input() contentRef: any;
  @Input() data: any;
  @Input() footerRef: any;
  @Input() footerData: any;

  @Input() noPadding: boolean = false;
  @Input() variant: string;

  constructor() {
  }

  ngOnInit() {
  }

}
