<div class="page-container">
  <h3 translate>generic.page-title.team-registry</h3>
  <div class="lds-dual-ring" *ngIf="false"></div>

  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/teams" translate>menu.system-configuration.label</a></li>
      <li class="breadcrumb-item active" aria-current="page" translate>generic.page-title.team-registry</li>
    </ol>
  </nav>
</div>

<section *ngIf="initialized">

  <div class="card my-4">
    <h5 class="card-header" translate>
      generic.section.registry
    </h5>

    <div class="card-body">
      <!-- Name -->
      <div class="form-group form-input-group form-inline row my-4">
        <label for="editNameText" class="form-input-label col-md-2" translate>
          generic.field.team
        </label>
        <div class="col-md-6">
          <input *ngIf="!readonly" type="text" maxlength="255" class="form-control form-input input-with-border"
                 [(ngModel)]="team.teamName"
                 [ngClass]="{ 'is-invalid': team.teamName=='' }"
                 name="editNameText" id="editNameText" required>
          <span *ngIf="readonly">{{ team.teamName }}</span>
          <div *ngIf="!readonly && team.teamName==''" class="invalid-feedback">
            <div *ngIf="team.teamName==''" translate>generic.message.validation.mandatory-error</div>
          </div>
        </div>
      </div>

      <div class="form-group form-input-group form-inline row my-4">
        <label for="editNameText" class="form-input-label col-md-2" translate>
          generic.field.company
        </label>
        <div class="col-md-6">
          <ng-select *ngIf="!readonly"
                     [ngClass]="{ 'is-invalid': team.societyId==null }"
                     name="editSocietySelect"
                     id="editSocietySelect"
                     [(ngModel)]="team.societyId"
                     class="custom"
                     [searchable]="true"
                     (ngModelChange)="onSocietyChange()">
            <ng-option *ngFor="let company of companies"
                       [value]="company.id">{{ company.name}}</ng-option>
          </ng-select>
          <span *ngIf="readonly">{{ team.society.name }}</span>
          <div *ngIf="!readonly && !team.societyId" class="invalid-feedback">
            <div *ngIf="!team.societyId" translate>generic.message.validation.mandatory-error</div>
          </div>
        </div>
      </div>

      <div class="form-group form-input-group form-inline row my-4" *ngIf="team.societyId">
        <label for="editParentSelect" class="form-input-label col-md-2" translate>
          generic.field.parentTeam
        </label>
        <div class="col-md-6">
          <ng-select *ngIf="!readonly"
                     [ngClass]="{ 'is-invalid': team.parentId==null }"
                     name="editParentSelect"
                     id="editParentSelect"
                     [(ngModel)]="team.parentId"
                     class="custom"
                     [searchable]="true">
            <ng-option *ngFor="let option of groups"
                       [value]="option.id">{{ option.teamName}}</ng-option>
          </ng-select>
          <span *ngIf="readonly">{{ team.parent.teamName }}</span>
        </div>
      </div>

    </div>
  </div>

  <section class="my-3">
    <div class="row">
      <div class="col">
        <div class="d-flex flex-row p-1">
          <button class="btn btn-default mr-auto" (click)="goTolist()"
                  [disabled]="loading">{{ 'generic.action.back' | translate }}</button>
          <button class="btn btn-primary" *ngIf="!readonly" (click)="save()"
                  [disabled]="loading">{{ 'generic.action.save' | translate }}
          </button>
        </div>
      </div>
    </div>
  </section>

</section>

<app-alert #alertModal [model]="" (onAnyCloseEmitter)="callbackApprovation($event)"></app-alert>
