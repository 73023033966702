import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {UsersComponent} from './components/users/users.component';
import {UserDetailWrapperComponent} from './components/users/user-detail-wrapper/user-detail-wrapper.component';
import {CompaniesComponent} from './components/companies/companies.component';
import {CompanyDetailComponent} from './components/companies/company-detail/company-detail.component';
import {LoginComponent} from './components/login/login.component';
import {GeneralSettingsComponent} from './components/general-settings/general-settings.component';
import {GeneralSettingsDetailComponent} from './components/general-settings/general-settings-detail/general-settings-detail.component';
import {ChangePasswordComponent} from './components/account/change-password/change-password.component';
import {ForgotPasswordComponent} from './components/account/forgot-password/forgot-password.component';
import {ResetPasswordComponent} from './components/account/reset-password/reset-password.component';
import {TeamsComponent} from './components/teams/teams.component';
import {TeamsDetailsComponent} from './components/teams/teams-details/teams-details.component';
import {AuthenticationGuard, AuthorizationGuard, AutologinGuard, PasswordManagerGuard} from '@pwc/security';
import {LayoutComponent} from './components/layout/layout.component';
import {RedirectService} from '@pwc/common';
import {Authorities} from './enums/security/authorities.enum';

const routes: Routes = [
  {
    path: 'login',
    // canActivate: [AutologinGuard],
    component: LoginComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'reset-password/:token',
    component: ResetPasswordComponent
  },
  {
    path: '',
    canActivate: [AuthenticationGuard, PasswordManagerGuard],
    pathMatch: 'full',
    loadChildren: () => import('./pages/home/home.module').then(
      m => m.HomeModule
    )
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthenticationGuard, AuthorizationGuard, PasswordManagerGuard],
    canActivateChild: [AuthenticationGuard, AuthorizationGuard, PasswordManagerGuard],
    children: [
      {
        path: 'users',
        // canActivate: [AuthenticationGuard, PasswordManagerGuard],
        component: UsersComponent,
        pathMatch: 'full'
      },
      {
        path: 'users/new',
        // canActivate: [AuthenticationGuard, PasswordManagerGuard],
        component: UserDetailWrapperComponent,
        pathMatch: 'full'
      },
      {
        path: 'users/:id/permissions/:permissions',
        // canActivate: [AuthenticationGuard, PasswordManagerGuard],
        component: UserDetailWrapperComponent,
        pathMatch: 'full',
        data: {
          tab: 'permissions'
        }
      },
      {
        path: 'users/:id/:permissions',
        // canActivate: [AuthenticationGuard, PasswordManagerGuard],
        component: UserDetailWrapperComponent,
        pathMatch: 'full',
        data: {
          tab: 'user'
        }
      },
      {
        path: 'teams',
        // canActivate: [AuthenticationGuard, PasswordManagerGuard],
        component: TeamsComponent,
        pathMatch: 'full'
      },
      {
        path: 'teams/new',
        // canActivate: [AuthenticationGuard, PasswordManagerGuard],
        component: TeamsDetailsComponent,
        pathMatch: 'full'
      },
      {
        path: 'teams/:id/:permissions',
        // canActivate: [AuthenticationGuard, PasswordManagerGuard],
        component: TeamsDetailsComponent,
        pathMatch: 'full'
      },
      {
        path: 'companies/new',
        // canActivate: [AuthenticationGuard, PasswordManagerGuard],
        component: CompanyDetailComponent,
        pathMatch: 'full'
      },
      {
        path: 'companies',
        // canActivate: [AuthenticationGuard, PasswordManagerGuard],
        component: CompaniesComponent,
        pathMatch: 'full'
      },
      {
        path: 'companies/new',
        // canActivate: [AuthenticationGuard, PasswordManagerGuard],
        component: CompanyDetailComponent,
        pathMatch: 'full'
      },
      {
        path: 'companies/:id/:permissions',
        // canActivate: [AuthenticationGuard, PasswordManagerGuard],
        component: CompanyDetailComponent,
        pathMatch: 'full'
      },
      {
        path: 'general-settings',
        // canActivate: [AuthenticationGuard],
        component: GeneralSettingsComponent,
        pathMatch: 'full'
      },
      {
        path: 'general-settings/new',
        // canActivate: [AuthenticationGuard, PasswordManagerGuard],
        component: GeneralSettingsDetailComponent,
        pathMatch: 'full'
      },
      {
        path: 'general-settings/:id/:permissions',
        // canActivate: [AuthenticationGuard, PasswordManagerGuard],
        component: GeneralSettingsDetailComponent,
        pathMatch: 'full'
      },
    ],
    data: {
      authorities: [
        Authorities.ROLE_USER_READ,
        Authorities.ROLE_USER_CREATE,
        Authorities.ROLE_USER_UPDATE,
        Authorities.ROLE_USER_DELETE,
        Authorities.ROLE_COMPANY_READ,
        Authorities.ROLE_TEAM_READ,
        Authorities.ROLE_TEAM_CREATE,
        Authorities.ROLE_TEAM_UPDATE,
        Authorities.ROLE_TEAM_DELETE,
        Authorities.ROLE_GENERAL_SETTING_VALUE_READ,
        Authorities.ROLE_GENERAL_SETTING_VALUE_CREATE,
        Authorities.ROLE_GENERAL_SETTING_VALUE_UPDATE,
        Authorities.ROLE_GENERAL_SETTING_VALUE_DELETE,
      ],
      redirect: ['/']
    }
  },
  {
    path: 'account',
    canActivate: [AuthenticationGuard],
    canActivateChild: [AuthenticationGuard],
    component: LayoutComponent,
    children: [
      {
        path: 'change-password',
        component: ChangePasswordComponent
      }
    ]
  },
  {
    path: 'redirect',
    canActivate: [RedirectService],
    component: LayoutComponent
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
