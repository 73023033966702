import {Component, OnInit} from '@angular/core';
import {NavigationService} from '../../services/navigation.service';
import {NavigationMenu} from '../../enums/navigation-menu.enum';
import {NavigationEnd, Router} from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  constructor(private navigationService: NavigationService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.setMenu();

    this.router.events.subscribe((value) => {
      if (value instanceof NavigationEnd) {
        // console.log('NavigationEnd');

        this.setMenu();
      }
    });
  }

  private setMenu(): void {
    this.navigationService.setActiveMenu(NavigationMenu.MAIN);
  }
}
