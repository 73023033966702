<div class="card">
  <div class="card-body">
    <h5 class="card-title">
      <span translate>generic.filters.label</span>
      <button class="btn btn-primary btn-sm btn-action-icon ml-0 btn-clear-filter" ngbTooltip="Svuota Filtri"
              (click)="resetFilters()" onmousedown="event.preventDefault();" type="button">
        <pwc-icon name="clear"></pwc-icon>
      </button>
    </h5>

    <div class="d-flex flex-row justify-content-between align-items-end">
      <div class="d-flex flex-row flex-wrap">

        <!--          ID filter -->
        <div class="form-group filter-item mr-4">
          <label class="form-input-label no-wrap-label" for="filterIDText" translate>
            generic.field.id
          </label>
          <div class="form-group form-input-group">
            <input class="form-control form-input no-shadow input-with-border" type="text" maxlength="255"
                   name="filterIDText" id="filterIDText"
                   [(ngModel)]="filter.id" (ngModelChange)="listTeams()"/>
          </div>
        </div>

        <!--          Name filter -->
        <div class="form-group filter-item mr-4">
          <label class="form-input-label no-wrap-label" for="filterGroupText" translate>
            generic.field.team
          </label>
          <div class="form-group form-input-group">
            <input class="form-control form-input no-shadow input-with-border" type="text" maxlength="255"
                   id="filterGroupText" name="filterGroupText"
                   [(ngModel)]="filter.teamName" (ngModelChange)="listTeams()"/>
          </div>
        </div>

        <div class="form-group filter-item mr-4">
          <label class="form-input-label no-wrap-label" for="editSocietySelect" translate>
            generic.field.society-name
          </label>
          <ng-select name="editSocietySelect"
                     id="editSocietySelect"
                     [(ngModel)]="filter.societyId"
                     (ngModelChange)="listTeams()"
                     [searchable]="true">
            <ng-option *ngFor="let company of companies"
                       [value]="company.id">{{ company.name}}</ng-option>
          </ng-select>
        </div>

      </div>
    </div>
  </div>
</div>

<div class="d-flex flex-row">
  <div class="card toolbar-card mb-3" *ngIf="selectedList.length>0">
    <div class="card-body card-table">
      <div class="d-flex p-2">
        <div class="m-2 pr-2 toolbar-box-border-right">
          <button class="btn btn-primary btn-sm btn-action-icon m-1" type="button"
                  ngbTooltip="{{ 'generic.action.delete.label' | translate }}"
                  *canAccess="requiredAuthorities.group.delete"
                  (click)="deleteMassiveGroups()">
            <img src="/assets/images/icons/delete.svg">
          </button>

          <button class="btn btn-primary btn-sm btn-action-icon m-1" type="button" (click)="export(true)"
                  ngbTooltip="{{ 'generic.action.export-excel' | translate }}">
            <img src="/assets/images/icons/export.svg">
          </button>
        </div>
        <div class="m-2 align-self-center">
            <span
              *ngIf="selectedCount>1"> {{ 'generic.selected-items-status.plural' | translate:{n: selectedCount} }} </span>
          <span *ngIf="selectedCount==1"> {{ 'generic.selected-items-status.singolar' | translate }}</span>
          &nbsp;<a [routerLink]="" *ngIf="selectedCount != total" (click)="selectAllRows()" translate>generic.action.select-all-label-status</a>
        </div>
      </div>
    </div>
  </div>
  <button type="button" class="btn btn-ghost ml-auto align-self-end text-nowrap my-3" (click)="export()"
          [routerLink]="">{{ 'generic.action.export-excel' | translate }}</button>
</div>

<div class="card">
  <div class="card-body card-table">
    <table class="table table-striped table-hover">
      <thead>
      <tr>
        <th scope="col" style="width: 15px">
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="selectAllRowsCheckBox" #checkall
                   name="selectAllRowsCheckBox" (change)="selectVisibleRows(selectAll)" [(ngModel)]="selectAll">
            <label class="custom-control-label" for="selectAllRowsCheckBox"></label>
          </div>
        </th>
        <th scope="col" sortable="id" (sort)="onSort($event)" translate>generic.field.id</th>
        <th scope="col" sortable="teamName" (sort)="onSort($event)" translate>generic.field.team</th>
        <th scope="col" sortable="society.name" (sort)="onSort($event)" translate>generic.field.society-name</th>
        <th scope="col" style="width: 50px"></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let team of teams">
        <th scope="row">
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" [attr.id]="team.id" [attr.name]="team.id"
                   [checked]="team.selected" [ngModelOptions]="{standalone: true}" [(ngModel)]="team.selected"
                   (change)="selectRows(team.id, team.selected)">
            <label class="custom-control-label" [attr.for]="team.id"></label>
          </div>
        </th>
        <td>
          {{team.id | idToStringPipe}}
        </td>
        <td>
          {{team.teamName | uppercase}}
        </td>
        <td>
          {{(team.society != null ? team.society.name : '') | uppercase}}
        </td>
        <td>
          <div style="white-space: nowrap;">
            <button type="button" class="btn btn-primary btn-sm btn-action-icon" (click)="onOpenDetail(team.id, 'r')"
                    ngbTooltip="{{ 'generic.action.show-item' | translate }}">
              <img src="/assets/images/icons/visualizza.svg">
            </button>
            <button type="button" class="btn btn-primary btn-sm btn-action-icon"
                    ngbTooltip="{{ 'generic.action.edit-item' | translate }}"
                    *canAccess="requiredAuthorities.group.update"
                    (click)="onOpenDetail(team.id, 'rw')">
              <img src="/assets/images/icons/modifica.svg">
            </button>
            <button type="button" class="btn btn-primary btn-sm btn-action-icon" (click)="deleteTeam(team.id)"
                    ngbTooltip="{{ 'generic.action.delete.label' | translate }}"
                    *canAccess="requiredAuthorities.group.delete">
              <img src="/assets/images/icons/elimina.svg">
            </button>
          </div>
        </td>
      </tr>
      </tbody>
    </table>

    <div class="d-flex justify-content-between p-2 table-paginator">
      <ngb-pagination
        (pageChange)="pageChange()"
        style="float: left"
        [maxSize]="5" [rotate]="true"
        [collectionSize]="total$ | async" [(page)]="filter.page" [pageSize]="filter.size">
      </ngb-pagination>

      <ng-select [(ngModel)]="filter.size"
                 style="width: auto;"
                 name="pageSize"
                 [clearable]="false"
                 class="custom"
                 [searchable]="false">
        <ng-option *ngFor="let elem of [ { value: 10 }, { value: 20 }, { value: 50 } ]"
                   [value]="elem.value">{{ elem.value }} {{ 'generic.action.switch-row-per-page' | translate }}
        </ng-option>
      </ng-select>
    </div>
  </div>
</div>
<div class="d-flex flex-row-reverse my-3 p-2"> <!--*canAccess="requiredAuthorities.company.create">-->
  <button type="button" class="btn btn-primary" routerLink="new">{{ 'generic.action.new' | translate }}</button>
</div>

<app-alert #alertModalList [model]="" (onAnyCloseEmitter)="callbackApprovation($event)"></app-alert>
