import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Company} from '../models/company.model';
import {SortDirection} from '../directives/sortable.directive';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {debounceTime, delay, first, switchMap, tap} from 'rxjs/operators';
import {SearchResult} from '../models/generics/search-result';
import {ExcelExportService} from './excel-export.service';
import {YesNoEnum} from '../enums/yes-no.enum';
import {UserService} from './user.service';
import {Authorities} from '../enums/security/authorities.enum';
import {isNullOrUndefined} from 'util';
import {AuthorizationService} from '@pwc/security';

interface State {
  page: number;
  pageSize: number;
  searchTerm: string;
  searchID: string;
  searchVatNumber: string;
  searchName: string;
  sortColumn: string;
  sortDirection: SortDirection;
}

function compare(v1, v2) {
  return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
}

function sort(list: Company[], column: string, direction: string): Company[] {
  if (direction === '') {
    return list;
  } else {
    return [...list].sort((a, b) => {
      const res = compare(a[column], b[column]);
      return direction === 'asc' ? res : -res;
    });
  }
}

function matches(company: Company, term: string) {
  return company.name.toLowerCase().includes(term);
}

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  private loading = new BehaviorSubject<boolean>(true);
  private search$ = new Subject<void>();
  private companiesList = new BehaviorSubject<Company[]>([]);
  private total = new BehaviorSubject<number>(0);
  companies: Company[];

  private filteredList = new BehaviorSubject<Company[]>([]);

  private state: State = {
    page: 1,
    pageSize: 10,
    searchTerm: '',
    searchID: '',
    searchVatNumber: '',
    searchName: '',
    sortColumn: '',
    sortDirection: ''
  };

  private isAdminSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isAdmin: Observable<boolean> = this.isAdminSubject.asObservable();

  private canReadSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public canRead: Observable<boolean> = this.canReadSubject.asObservable();

  requiredAuthorities = {
    company: {
      read: Authorities.ROLE_COMPANY_READ,
      create: Authorities.ROLE_COMPANY_CREATE,
      update: Authorities.ROLE_COMPANY_UPDATE,
      delete: Authorities.ROLE_COMPANY_DELETE,
    },
    parentCompany: {
      read: Authorities.ROLE_PARENT_COMPANY_READ,
      create: Authorities.ROLE_PARENT_COMPANY_CREATE,
      update: Authorities.ROLE_PARENT_COMPANY_UPDATE,
      delete: Authorities.ROLE_PARENT_COMPANY_DELETE,
    }
  };

  constructor(private http: HttpClient,
              private excelService: ExcelExportService,
              private authorizationService: AuthorizationService,
              private userService: UserService) {
    this.loadAuthorities();
  }

  loadService() {
    this.isAdmin.subscribe(admin => {
      this.loadSocieties(admin);
    });
  }

  private loadSocieties(all = true) {
    const getSocietiesData = all ? this.getAll() : this.userService.getSocieties(null, true);
    getSocietiesData.pipe(first()).subscribe(list => {
      this.companies = list;
      // console.log('list', this.companies);
      this.search$.pipe(
        tap(() => this.loading.next(true)),
        debounceTime(200),
        switchMap(() => this.search()),
        delay(200),
        tap(() => this.loading.next(false))
      ).subscribe((result: SearchResult<Company>) => {
        this.companiesList.next(result.items);
        this.filteredList.next(result.filteredList);
        this.total.next(result.total);
      });

      this.search$.next();
    });
  }

  loadAuthorities() {
    this.authorizationService.checkAuthorities(this.requiredAuthorities.parentCompany.create)
      .subscribe((authorized: boolean) => {
        console.log('canEditParentCompany', authorized);
        this.isAdminSubject.next(authorized);
      });
    this.authorizationService.checkAuthorities(this.requiredAuthorities.company.read)
      .subscribe((authorized: boolean) => {
        console.log('canEditParentCompany', authorized);
        this.canReadSubject.next(authorized);
      });
  }

  getAll() {
    return this.http.get<Company[]>(`${environment.apiUrl}/societies`);
  }

  getParentCompanies() {
    return this.http.get<Company[]>(`${environment.apiUrl}/societies?isParentCompany=${YesNoEnum.YES}`);
  }

  addCompany(company: Company) {
    return this.http.post(`${environment.apiUrl}/societies`, company);
  }

  updateCompany(company: Company) {
    return this.http.put(`${environment.apiUrl}/societies`, company);
  }

  getCompany(id: any) {
    return this.http.get<Company>(`${environment.apiUrl}/societies/${id}`);
  }

  deleteCompany(id: number) {
    return this.http.delete<Company>(`${environment.apiUrl}/societies/${id}`);
  }

  deleteAllCompanies(list: any[]) {
    const params = list.map(id => `ids=${id}`).join('&');
    return this.http.delete(`${environment.apiUrl}/societies?${params}`);
  }

  private search(): Observable<SearchResult<Company>> {
    const {
      page,
      pageSize,
      searchTerm,
      searchID,
      searchVatNumber,
      searchName,
      sortColumn,
      sortDirection
    } = this.state;

    // 1. sort
    let list = sort(this.companies, sortColumn, sortDirection);

    // 2. filter
    list = list.filter(user => matches(user, searchTerm));

    if (searchVatNumber !== '') {
      list = list.filter(company => company.vatNumber.toLowerCase().includes(searchVatNumber.toLowerCase()));
    }

    if (searchID !== '') {
      // list = list.filter(user => user.id === parseInt(searchID, 10)); // search matching ID
      list = list.filter(user => user.id.toString().toLowerCase().includes(searchID));
    }

    if (searchName !== '') {
      list = list.filter(company => company.name.toLowerCase().includes(searchName.toLowerCase()));
    }

    const total = list.length;
    const filteredList = JSON.parse(JSON.stringify(list));
    // console.log('filteredList', filteredList);

    // 3. paginate
    list = list.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    return of({items: list, filteredList, total});
  }

  get companiesList$() {
    return this.companiesList.asObservable();
  }

  get filteredList$() {
    return this.filteredList.asObservable();
  }

  get total$() {
    return this.total.asObservable();
  }

  get loading$() {
    return this.loading.asObservable();
  }

  get sortColumn() {
    return this.state.sortColumn;
  }

  set sortColumn(sortColumn: string) {
    this.setState({sortColumn});
  }

  get sortDirection() {
    return this.state.sortDirection;
  }

  set sortDirection(sortDirection: SortDirection) {
    this.setState({sortDirection});
  }

  get searchID() {
    return this.state.searchID;
  }

  set searchID(searchID: string) {
    this.setState({searchID});
  }

  get searchVatNumber() {
    return this.state.searchVatNumber;
  }

  set searchVatNumber(searchVatNumber: string) {
    this.setState({searchVatNumber});
  }

  get searchName() {
    return this.state.searchName;
  }

  set searchName(searchName: string) {
    this.setState({searchName});
  }

  get searchTerm() {
    return this.state.searchTerm;
  }

  set searchTerm(searchTerm: string) {
    this.setState({searchTerm});
  }

  get page() {
    return this.state.page;
  }

  set page(page: number) {
    this.setState({page});
  }

  get pageSize() {
    return this.state.pageSize;
  }

  set pageSize(pageSize: number) {
    this.setState({pageSize});
  }

  private setState(patch: Partial<State>) {
    Object.assign(this.state, patch);
    this.search$.next();
  }

  public exportAsExcel(exportedList: any[]) {
    this.excelService.exportAsExcelFile(exportedList, 'Data');
  }
}
