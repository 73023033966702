import {Injectable} from '@angular/core';
import {HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {delay, mergeMap, materialize, dematerialize} from 'rxjs/operators';

import {User} from '../models/user.model';

@Injectable()
export class BackendInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    /*const users: User[] = [
      {id: 1, username: 'admin', password: 'pwc2019', firstName: 'Mario', lastName: 'Rossi', language: 'IT', true}
    ];*/

    const authHeader = request.headers.get('Authorization');
    const isLoggedIn = authHeader && authHeader.startsWith('Bearer fake-jwt-token');

    // wrap in delayed observable to simulate server api call
    return of(null).pipe(mergeMap(() => {

      // authenticate - public
      /*if (request.url.endsWith('/users/authenticate') && request.method === 'POST') {
        const user = users.find(x => x.username === request.body.username && x.password === request.body.password);
        if (!user) {
          return error('Username o password errati');
        }
        return ok({
          id: user.id,
          username: user.username,
          firstName: user.firstName,
          lastName: user.lastName,
          token: 'fake-jwt-token'
        });
      }*/


      // get all users
        /*
      if (request.url.endsWith('/users') && request.method === 'GET') {
        if (!isLoggedIn) {
          return unauthorised();
        }
        const dupReq = request.clone({
          url: 'assets/mock/users-list.json'
        });
        return next.handle(dupReq);
      }
      */
/*
      if (request.url.endsWith('/societies') && request.method === 'GET') {
        const dupReq = request.clone({
          url: 'assets/mock/company-list.json'
        });
        return next.handle(dupReq);
      } */
/*
      if (request.url.endsWith('/roles') && request.method === 'GET') {
        const dupReq = request.clone({
          url: 'assets/mock/roles-list.json'
        });
        return next.handle(dupReq);
      }

      if (request.url.endsWith('/document-classes') && request.method === 'GET') {
        const dupReq = request.clone({
          url: 'assets/mock/document-class-list.json'
        });
        return next.handle(dupReq);
      }
      */

      /*
      // get all companies
      if (request.url.includes('/societies') && request.method === 'GET') {
        if (!isLoggedIn) {
          return unauthorised();
        }

        const dupReq = request.clone({
          url: 'assets/mock/company-list.json'
        });
        return next.handle(dupReq);
      }*/

      /*
      if (request.url.endsWith('/countries') && request.method === 'GET') {
        const dupReq = request.clone({
          url: 'assets/mock/countries-list.json'
        });
        return next.handle(dupReq);
      } */
        /*
      if (request.url.endsWith('/ateco') && request.method === 'GET') {
        const dupReq = request.clone({
          url: 'assets/mock/ateco-industries.json'
        });
        return next.handle(dupReq);
      }*/

      if (request.url.endsWith('/upload') && request.method === 'POST') {
        if (!isLoggedIn) {
          return unauthorised();
        }
        const resp = {
          status: 'success',
          message: 'Upload ok'
        };
        return ok(resp);
      }

      // pass through any requests not handled above
      return next.handle(request);
    }))
    //    call materialize and dematerialize to ensure delay even if an error is thrown (https://github.com/Reactive-Extensions/RxJS/issues/648)
      .pipe(materialize())
      .pipe(delay(500))
      .pipe(dematerialize());

    // private helper functions
    function ok(body) {
      return of(new HttpResponse({status: 200, body}));
    }

    function unauthorised() {
      return throwError({status: 401, error: {message: 'Unauthorised'}});
    }

    function error(message) {
      return throwError({status: 400, error: {message}});
    }
  }
}

export let fakeBackendProvider = {
  // use fake backend in place of Http service for backend-less development
  provide: HTTP_INTERCEPTORS,
  useClass: BackendInterceptor,
  multi: true
};
