import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NavigationService} from '../../services/navigation.service';
import {NavigationMenu} from '../../enums/navigation-menu.enum';
import {AuthenticationService} from '@pwc/security';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent implements OnInit {
  showLoading = true;
  permissions: string;

  constructor(private router: Router,
              private navigationService: NavigationService,
              private authenticationService: AuthenticationService) {
  }

  ngOnInit(): void {
    this.showLoadingHandler(false);
      this.authenticationService.checkAdmin();
    this.permissions = 'rw';
    this.navigationService.setActiveMenu(NavigationMenu.SETTINGS);
  }

  showLoadingHandler(show: boolean = true) {
    this.showLoading = show;
  }

  openDetail(id: number, perm: string) {
    this.router.navigateByUrl('companies/' + id + '/' + perm);
  }

  openDetailHandler(obj: any) {
    // this.userName = $event;
    this.openDetail(obj.id, obj.permission);
  }
}
