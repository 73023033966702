import {NgModule} from '@angular/core';
import {CommonModule} from '@pwc/common';
import {LayoutComponent} from './layout.component';
import {HeaderComponent} from './header/header.component';
import {SidebarComponent} from './sidebar/sidebar.component';
import {LayoutModule, UIModule} from '@pwc/ui';
import {FooterComponent} from './footer/footer.component';
import {I18nModule} from '@pwc/i18n';
import {TranslateModule} from '@ngx-translate/core';
import {NgSelectModule} from '@ng-select/ng-select';
import {RouterModule} from '@angular/router';
import {SecurityModule} from '@pwc/security';

@NgModule({
  declarations: [
    LayoutComponent,
    HeaderComponent,
    SidebarComponent,
    FooterComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    LayoutModule,
    UIModule,
    I18nModule,
    TranslateModule.forChild(),
    NgSelectModule,
    SecurityModule
  ],
  exports: [
    LayoutComponent,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    LayoutModule
  ]
})
export class FrameworkLayoutModule {
}
