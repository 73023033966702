import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-widget-communico',
  templateUrl: './widget-communico.component.html',
  styleUrls: ['./widget-communico.component.scss']
})
export class WidgetCommunicoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
