import {EventEmitter, Inject, Injectable} from '@angular/core';
import {QueueService} from '..';
import {DownloadItem} from '../../models/storage/download-item';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {DOWNLOAD_BASEURL} from '../../utils/tokens';
import {Observable, timer} from 'rxjs';
import {first, map, switchMap, takeWhile, tap} from 'rxjs/operators';
import {DownloadStatus} from '../../models/storage/download-status';
import {HttpUtils} from '../../utils/http-utils';
import {flatMap} from 'rxjs/internal/operators';
import {DownloadStatusEnum} from '../../enum/download-status.enum';
import {StringUtils} from '../../utils/string-utils';
import {ApiResource} from '../../models';

@Injectable({
  providedIn: 'root'
})
export class DownloaderService extends QueueService<DownloadItem> {
  private readonly FILENAME_REGEX = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;

  queue: DownloadItem[] = [];

  onDownloadCompleted: EventEmitter<DownloadItem> = new EventEmitter<DownloadItem>();

  constructor(private http: HttpClient,
              @Inject(DOWNLOAD_BASEURL) protected baseUrl: string) {
    super();
  }

  removeById(id: number): void {
    this.queue = this.queue.filter(item => item.id !== id);
  }

  public removeAll(): void {
    this.queue = [];
  }

  getQueue(): DownloadItem[] {
    return this.queue;
  }

  addToQueue(item: DownloadItem): void {
    this.queue.push(item);
  }

  get isDownloading(): boolean {
    return this.queue.filter(val => val.isProgress).length > 0;
  }

  get isError(): boolean {
    return this.hasItems && !this.isDownloading && this.queue.filter((item: DownloadItem) => item.isError).length > 0;
  }

  get isSuccess(): boolean {
    return this.hasItems && !this.isDownloading && !this.isError;
  }

  get totalItemsCount(): number {
    return this.queue.length;
  }

  get hasItems(): boolean {
    return this.totalItemsCount > 0;
  }

  public exportList<T extends ApiResource>(endpoint: string, search: Partial<T>): Observable<any> {
    const url = `${this.baseUrl}/${endpoint}/download-export-csv`;

    return this.http.post<any>(url, search)
      .pipe(
        first(),
        tap((status: DownloadStatus) => {
          this.download({
            id: status.id,
            token: status.token,
            fileName: status.fileName,
            url: endpoint + '/download'
          });
        })
      );
  }

  public exportDetail<T extends ApiResource>(endpoint: string, search: Partial<T>): Observable<any> {
    const url = `${this.baseUrl}/${endpoint}/download-export-csv-detail`;

    return this.http.post<any>(url, search)
      .pipe(
        first(),
        tap((status: DownloadStatus) => {
          this.download({
            id: status.id,
            token: status.token,
            fileName: status.fileName,
            url: endpoint + '/download'
          });
        })
      );
  }

  public downloadElements(items: DownloadItem | DownloadItem[], params?: any): Observable<any> {
    let ids = [];
    let path = '';
    if (Array.isArray(items) && items.length) {
      ids = items.map((item: DownloadItem) => item.id);
      path = items[0].url;
    } else {
      ids = [(items as DownloadItem).id];
      path = (items as DownloadItem).url;
    }

    const url = `${this.baseUrl}/${path}`;

    return this.http.post<any>(url, {ids, ...params})
      .pipe(
        first(),
        tap((status: DownloadStatus) => {
          this.download({
            id: status.id,
            token: status.token,
            fileName: status.fileName,
            url: path
          });
        })
      );
  }

  public getDownloadStatus(item: DownloadItem): Observable<DownloadStatus> {
    const request = {
      id: item.id
    };

    const url = `${this.baseUrl}/${item.url}/status`;

    return this.http.post<DownloadStatus>(url, request)
      .pipe(first());
  }

  public download(item: DownloadItem): void {
    this.addToQueue(item);
    this.startDownloading();
  }

  public downloadMultiple(items: DownloadItem[]): void {
    for (const item of items) {
      this.addToQueue(item);
    }

    this.startDownloading();
  }

  public startDownloading(): void {
    const items = this.queue.filter(item => !item.isProgress);

    for (const item of items) {
      if (item.isError || item.isSuccess) {
        continue;
      }

      item.isProgress = true;
      const call = timer(0, 2000)
        .pipe(
          flatMap(() => this.getDownloadStatus(item).pipe(
            takeWhile(val => val.status === DownloadStatusEnum.READY || val.status === DownloadStatusEnum.ERROR),
            tap(val => {
              item.fileName = val.fileName;
              item.isError = val.status === DownloadStatusEnum.ERROR;
              item.isSuccess = val.status === DownloadStatusEnum.READY;
              if (val.url) {
                item.url = val.url;
              }
            })
          )),
          first(),
          switchMap(value => {
            return this.downloadByToken(value.token, item.url);
          })
        )
        .subscribe((res: Partial<DownloadItem>) => {
          item.isProgress = false;

          if (item.isSuccess) {
            if (!StringUtils.isEmpty(res.fileName)) {
              item.fileName = res.fileName;
            }

            item.url = res.url;
            if (!StringUtils.isEmpty(item.fileName) && item.fileName.endsWith('.csv')) {
              item.blob = new Blob(['\ufeff', res.rawData]);
            } else {
              item.blob = new Blob([res.rawData]);
            }
            item.rawData = res.rawData;

            call.unsubscribe();
            this.onDownloadCompleted.emit(item);
          }
        }, error => {
          console.error(error);

          item.isSuccess = false;
          item.isProgress = false;
          item.isError = true;
        });
    }
  }

  public downloadById(id: number, endpoint: string): Observable<Partial<DownloadItem>> {
    const params = HttpUtils.toHttpParams({id});

    const url = `${this.baseUrl}/${endpoint}/byId`;

    return this.downloadBlob(url, params);
  }

  public downloadByToken(token: string, endpoint: string): Observable<Partial<DownloadItem>> {
    const params = HttpUtils.toHttpParams({token});

    const url = `${this.baseUrl}/${endpoint}/byToken`;

    return this.downloadBlob(url, params);
  }

  protected downloadBlob(url: string, params: HttpParams): Observable<Partial<DownloadItem>> {
    return this.getBlob<Blob>(url, params)
      .pipe(
        map((response: HttpResponse<Blob>) => {
          let fileName = '';
          const contentDisposition = response.headers.get('Content-Disposition');
          const matches = this.FILENAME_REGEX.exec(contentDisposition);

          if (matches != null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, '');
          }

          const blob = new Blob(['\ufeff', response.body]);
          const rawData = response.body;

          return {
            rawData,
            blob,
            url: URL.createObjectURL(blob),
            fileName
          };
        })
      );
  }

  protected getBlob<Blob>(url: string, params: HttpParams, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'arraybuffer';
    withCredentials?: boolean;
  }): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(url, {
      params,
      responseType: 'blob' as 'json',
      observe: 'response'
    }).pipe(first());
  }

  clear() {
    this.removeAll();
  }

  isEmpty(): boolean {
    return !this.isNotEmpty();
  }

  isNotEmpty(): boolean {
    return this.hasItems;
  }

  count(): number {
    return this.totalItemsCount;
  }
}
