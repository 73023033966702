import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Data, Router} from '@angular/router';
import {NgbTabChangeEvent} from '@ng-bootstrap/ng-bootstrap';
import {NavigationService} from '../../../services/navigation.service';
import {NavigationMenu} from '../../../enums/navigation-menu.enum';

@Component({
  selector: 'app-user-detail-wrapper',
  templateUrl: './user-detail-wrapper.component.html',
  styleUrls: ['./user-detail-wrapper.component.scss']
})
export class UserDetailWrapperComponent implements OnInit, AfterViewInit {

  @ViewChild('t', { static: true }) tabsetRef: ElementRef;
  private id: number;
  private permissions: string;
  public mode: string;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private navigationService: NavigationService) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      // console.log('params', params);
        if (this.router.url.endsWith('/new')) {
            this.mode = 'new';
        } else
        {
            this.mode = 'edit';
            this.id = +params.id;
            this.permissions = params.permissions;
        }
    });
    this.navigationService.setActiveMenu(NavigationMenu.SETTINGS);
  }

  ngAfterViewInit() {
    this.route.data.subscribe((data: Data) => {
      // @ts-ignore
      this.tabsetRef.select(data.tab);
    });
  }

  changeTab(e: NgbTabChangeEvent) {
    if (e.nextId === 'permissions') {
      this.router.navigateByUrl('users/' + this.id + '/permissions/' + this.permissions);
    } else {
      this.router.navigateByUrl('users/' + this.id + '/' + this.permissions);
    }
  }
}


