import {LocalTranslateEnum} from '../enums/local-translate.enum';
import {LanguageEnum} from '../enums/language.enum';
import {isNullOrUndefined} from 'util';
import {Translation} from '../models/translation.model';

export function downloadBlob(blob: any, filename: string) {
  const blobURL = URL.createObjectURL(blob);
  const tempLink = window.document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = blobURL;
  tempLink.setAttribute('download', filename);
  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank');
  }
  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    URL.revokeObjectURL(blobURL);
  }, 100);
}


export function getBlobUrl(blob: any) {
  return URL.createObjectURL(blob);
}

export function groupBy(xs: any[], key: any) {
  return xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

export function checkTypeOfList(list: any[], clazz: any) {
  // console.log('checkTypeOfList', list.length > 0 ? list[0] instanceof clazz : false);
  return list.length > 0 ? list[0] instanceof clazz : false;
}

export function mapTranslateMessages(translated: any[]) {

  const messages = {
    errorSaving: translated[LocalTranslateEnum.errorSaving],
    errorLoading: translated[LocalTranslateEnum.errorLoading],
    deleteConfirm: translated[LocalTranslateEnum.deleteConfirm],
    deleteSelectedConfirm: translated[LocalTranslateEnum.deleteSelectedConfirm],
    deleteSuccess: translated[LocalTranslateEnum.deleteSuccess],
    savingSuccess: translated[LocalTranslateEnum.savingSuccess],
    approveSelectedConfirm: translated[LocalTranslateEnum.approveSelectedConfirm],
    cloneSelectdConfirm: translated[LocalTranslateEnum.cloneSelectdConfirm],
    cloneSuccess: translated[LocalTranslateEnum.cloneSuccess],
    errorGeneric: translated[LocalTranslateEnum.errorGeneric],
    rejectNoteInfoSet: translated[LocalTranslateEnum.rejectNoteInfoSet],
    scenarioErrorInfoSetNotApproved: translated[LocalTranslateEnum.scenarioErrorInfoSetNotApproved],
    scenarioStartSuccess: translated[LocalTranslateEnum.scenarioStartSuccess],
    scernarioAlertImfoSetMissing: translated[LocalTranslateEnum.scernarioAlertImfoSetMissing],
    approveSuccess: translated[LocalTranslateEnum.approveSuccess],
    scenarioIndicesListTitle: translated[LocalTranslateEnum.scenarioIndicesListTitle],
    rejectSuccess: translated[LocalTranslateEnum.rejectSuccess],
    rejectInfoSetSuccess: translated[LocalTranslateEnum.rejectInfoSetSuccess],
    usersOwnerActivity: translated[LocalTranslateEnum.usersOwnerActivity],
    remedationActionNote: translated[LocalTranslateEnum.remedationActionNote],
    reportCreatedSuccess: translated[LocalTranslateEnum.reportCreatedSuccess],
    yesAnswer: translated[LocalTranslateEnum.yesAnswer],
    noAnswer: translated[LocalTranslateEnum.noAnswer],
    ruleSetValue: translated[LocalTranslateEnum.ruleSetValue],
    ruleIf: translated[LocalTranslateEnum.ruleIf],
    ruleElseIf: translated[LocalTranslateEnum.ruleElseIf],
    ruleElse: translated[LocalTranslateEnum.ruleElse],
    ruleAnd: translated[LocalTranslateEnum.ruleAnd],
    ruleOr: translated[LocalTranslateEnum.ruleOr],
    ruleValue: translated[LocalTranslateEnum.ruleValue],
    ruleTrafficLightYellow: translated[LocalTranslateEnum.ruleTrafficLightYellow],
    ruleTrafficLightGreen: translated[LocalTranslateEnum.ruleTrafficLightGreen],
    ruleTrafficLightGrey: translated[LocalTranslateEnum.ruleTrafficLightGrey],
    ruleTrafficLightRed: translated[LocalTranslateEnum.ruleTrafficLightRed],
    ruleResult: translated[LocalTranslateEnum.ruleResult],
    ruleResultFormatPerc: translated[LocalTranslateEnum.ruleResultFormatPerc],
    ruleResultFormatNumber: translated[LocalTranslateEnum.ruleResultFormatNumber],
    ruleResultFormatNumberCurrency: translated[LocalTranslateEnum.ruleResultFormatNumberCurrency],
    ruleResultFormatAnswer: translated[LocalTranslateEnum.ruleResultFormatAnswer],
    legendMonitoringDone: translated[LocalTranslateEnum.legendMonitoringDone],
    legendMonitoringExpiring: translated[LocalTranslateEnum.legendMonitoringExpiring],
    legendMonitoringOverdue: translated[LocalTranslateEnum.legendMonitoringOverdue],
    legendMonitoringTodo: translated[LocalTranslateEnum.legendMonitoringTodo],
    fieldAnnual: translated[LocalTranslateEnum.fieldAnnual],
    fieldSemiannual: translated[LocalTranslateEnum.fieldSemiannual],
    fieldMonthly: translated[LocalTranslateEnum.fieldMonthly],
    fieldQuarterly: translated[LocalTranslateEnum.fieldQuarterly],
    fieldSemester: translated[LocalTranslateEnum.fieldSemester],
    fieldQuarter: translated[LocalTranslateEnum.fieldQuarter],
    fieldJanuary: translated[LocalTranslateEnum.fieldJanuary],
    fieldFebruary: translated[LocalTranslateEnum.fieldFebruary],
    fieldMarch: translated[LocalTranslateEnum.fieldMarch],
    fieldApril: translated[LocalTranslateEnum.fieldApril],
    fieldMay: translated[LocalTranslateEnum.fieldMay],
    fieldJune: translated[LocalTranslateEnum.fieldJune],
    fieldJuly: translated[LocalTranslateEnum.fieldJuly],
    fieldAugust: translated[LocalTranslateEnum.fieldAugust],
    fieldSeptember: translated[LocalTranslateEnum.fieldSeptember],
    fieldOctober: translated[LocalTranslateEnum.fieldOctober],
    fieldNovember: translated[LocalTranslateEnum.fieldNovember],
    fieldDecember: translated[LocalTranslateEnum.fieldDecember],
    fieldPeriod: translated[LocalTranslateEnum.fieldPeriod],
    validationFieldDuplicated: translated[LocalTranslateEnum.validationFieldDuplicated],
    validationFieldInvalid: translated[LocalTranslateEnum.validationFieldInvalid],
  };

  return messages;
}

export const tranlastionKeys = [
  LocalTranslateEnum.errorSaving,
  LocalTranslateEnum.errorLoading,
  LocalTranslateEnum.deleteConfirm,
  LocalTranslateEnum.deleteSelectedConfirm,
  LocalTranslateEnum.deleteSuccess,
  LocalTranslateEnum.savingSuccess,
  LocalTranslateEnum.approveSelectedConfirm,
  LocalTranslateEnum.cloneSelectdConfirm,
  LocalTranslateEnum.cloneSuccess,
  LocalTranslateEnum.errorGeneric,
  LocalTranslateEnum.rejectNoteInfoSet,
  LocalTranslateEnum.scenarioErrorInfoSetNotApproved,
  LocalTranslateEnum.scenarioStartSuccess,
  LocalTranslateEnum.scernarioAlertImfoSetMissing,
  LocalTranslateEnum.approveSuccess,
  LocalTranslateEnum.scenarioIndicesListTitle,
  LocalTranslateEnum.rejectSuccess,
  LocalTranslateEnum.rejectInfoSetSuccess,
  LocalTranslateEnum.usersOwnerActivity,
  LocalTranslateEnum.remedationActionNote,
  LocalTranslateEnum.reportCreatedSuccess,
  LocalTranslateEnum.yesAnswer,
  LocalTranslateEnum.noAnswer,
  LocalTranslateEnum.ruleSetValue,
  LocalTranslateEnum.ruleIf,
  LocalTranslateEnum.ruleElseIf,
  LocalTranslateEnum.ruleElse,
  LocalTranslateEnum.ruleAnd,
  LocalTranslateEnum.ruleOr,
  LocalTranslateEnum.ruleValue,
  LocalTranslateEnum.ruleTrafficLightRed,
  LocalTranslateEnum.ruleTrafficLightYellow,
  LocalTranslateEnum.ruleTrafficLightGreen,
  LocalTranslateEnum.ruleTrafficLightGrey,
  LocalTranslateEnum.ruleResult,
  LocalTranslateEnum.ruleResultFormatPerc,
  LocalTranslateEnum.ruleResultFormatNumber,
  LocalTranslateEnum.ruleResultFormatNumberCurrency,
  LocalTranslateEnum.ruleResultFormatAnswer,
  LocalTranslateEnum.legendMonitoringDone,
  LocalTranslateEnum.legendMonitoringExpiring,
  LocalTranslateEnum.legendMonitoringOverdue,
  LocalTranslateEnum.legendMonitoringTodo,
  LocalTranslateEnum.fieldAnnual,
  LocalTranslateEnum.fieldSemiannual,
  LocalTranslateEnum.fieldMonthly,
  LocalTranslateEnum.fieldQuarterly,
  LocalTranslateEnum.fieldSemester,
  LocalTranslateEnum.fieldQuarter,
  LocalTranslateEnum.fieldJanuary,
  LocalTranslateEnum.fieldFebruary,
  LocalTranslateEnum.fieldMarch,
  LocalTranslateEnum.fieldApril,
  LocalTranslateEnum.fieldMay,
  LocalTranslateEnum.fieldJune,
  LocalTranslateEnum.fieldJuly,
  LocalTranslateEnum.fieldAugust,
  LocalTranslateEnum.fieldSeptember,
  LocalTranslateEnum.fieldOctober,
  LocalTranslateEnum.fieldNovember,
  LocalTranslateEnum.fieldDecember,
  LocalTranslateEnum.fieldPeriod,
  LocalTranslateEnum.validationFieldDuplicated,
  LocalTranslateEnum.validationFieldInvalid,
];

export const systemLanguages = [
  LanguageEnum.IT,
  LanguageEnum.EN
];


export function initTranslationsLanguages(map, defaultField?: string, defaultValue?: any) {

  if (isNullOrUndefined(map)) {
    const tmp = new Map<string, Translation>();
    map = tmp;
  }
  for (let lang of systemLanguages) {
    if (isNullOrUndefined(map[lang])) {
      const missing = new Translation('', '', '', lang);
      map[lang] = missing;
      // map.set(lang, missing);
    }
    if (!isNullOrUndefined(defaultValue)) {
      map[lang][defaultField] = defaultValue;
    }
  }
  return map;
}
